import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { faFile, faArrowLeft, faCog } from "@fortawesome/free-solid-svg-icons";
import "./NavigationBar.css";
import { debounce } from "lodash";
import Logo from "../../../assets/images/deasie.png";
import { Logout } from "../functions/authentication";
import { sendRequest } from "../functions/api";
import { ENDPOINTS } from "../../../api/endpoints";
import { Auth } from "aws-amplify";
import { isFileContainedInArray, objectDeepCopy } from "../functions/utils";
import { DataContext } from "../../../context/DataContext";
import { UsecaseContext } from "../../../context/UsecaseContext";
import Export from "./Components/Export/Export";
import { TagContext } from "../../../context/TagContext";
import FolderList from "./FolderList";
import { useAtom } from "jotai";
import { darkModeAtom } from "../../../atoms";
import { DocumentsSelector } from "../DocumentsSelecor/DocumentsSelector";
import RunningTasksModal from "../RunningTasksModal";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "../Toast";
import { SchedulingModalContent } from "../SchedulingModalContent";
import { useUserProfile } from "../../../context/UserProfile";
export default function NavigationBar() {
  const [showModal, setShowModal] = useState(false);
  const [showRunningTasksModal, setShowRunningTasksModal] = useState(false);
  const [view, setView] = useState("options");
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [isModalLoading, setModalLoading] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pii, setPii] = useState(false);
  const [schedulingOptions, setSchedulingOptions] = useState({});
  const [checkSensitivty, setCheckSensitivity] = useState(false);
  const [integration, setIntegration] = useState("s3");
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredFolderKeys, setFilteredFolderKeys] = useState([]);
  const [filePreviewContent, setFilePreviewContent] = useState("");
  const [showExportLogoutDropdown, setShowExportLogoutDropdown] =
    useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);

  const {
    setShowScreen,
    showScreen,
    catalogSummary,
    catalogFiles,
    availableTags,
    quarantinedFiles,
    setCurrentTag,
    preferences,
    usedCatalog,
  } = useContext(DataContext);
  const { permissions } = useUserProfile();

  const { setIsActiveAction } = useContext(TagContext);

  const { setUsecaseStage } = useContext(UsecaseContext);

  const dropdownRef = useRef();

  const [selectedTags, setSelectedTags] = useState({});

  const navigate = useNavigate();
  const listContainerRef = useRef();

  useEffect(() => {
    const defaultTags = Object.keys(catalogSummary).reduce((acc, category) => {
      const availableTagsKeys = Object.keys({
        ...availableTags.llm.tagger_params.tag_dict,
      });
      if (availableTagsKeys.includes(category)) {
        acc[category] = true;
      }
      return acc;
    }, {});
    const handleClickOutside = (event) => {
      if (
        showTagsDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowTagsDropdown(false);
      }
    };

    if (showTagsDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    setSelectedTags(defaultTags);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goToUsecase = () => {
    setShowScreen("usecase");
    setUsecaseStage("menu");
  };

  const handleTagChange = (category) => {
    setSelectedTags((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const handleSelectAllToggle = (event, tagCategories) => {
    const newSelectedState = event.target.checked;
    const updatedTags = {};
    tagCategories.forEach((category) => {
      updatedTags[category] = newSelectedState;
    });

    setSelectedTags(updatedTags);
  };

  const renderTagSelection = () => {
    const tagCategories = Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
    });

    const allTagsSelected = tagCategories.every(
      (category) => selectedTags[category]
    );

    return (
      <div className="TagSelectionContainer">
        <div className="TagSelectionHeader">
          {/* <label className="pii-toggle-label-tag-selection">
            PII Redaction
            <input
              type="checkbox"
              checked={pii}
              onChange={() => setPii(!pii)}
              className="pii-toggle-checkbox-tag-selection"
            />
            <span className="pii-toggle-slider-tag-selection"></span>
          </label> */}
          <div className="SensitivityCheckDropdownContainer">
            <label
              htmlFor="sensitivityCheckDropdown"
              className="text-black text-m mb-1"
            >
              Sensitivity Check
            </label>
            <select
              id="sensitivityCheckDropdown"
              className="SensitivityCheckDropdown"
              onChange={(e) => setCheckSensitivity(e.target.value)}
              value={checkSensitivty}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
            <div className="Explanation">
              <p>
                <strong>Yes:</strong> Performs all sensitivity checks tagging
                the dataset and if triggered quarantines the dataset
                <br />
                <strong>No:</strong> Tags the dataset without running additional
                sensitivity checks before tagging
              </p>
            </div>
          </div>
          <label className="selectAllLabel">
            <input
              type="checkbox"
              className="selectAllCheckbox"
              onChange={(e) => handleSelectAllToggle(e, tagCategories)}
              checked={allTagsSelected}
            />
            Select all available tags to be used for tagging
          </label>
        </div>
        {tagCategories.map((category) => (
          <div key={category} className="TagCategory">
            <h3>{category}</h3>
            <label>
              <input
                type="checkbox"
                className="hiddenCheckbox"
                checked={selectedTags[category]}
                onChange={() => handleTagChange(category)}
              />
              <span className="customCheckbox"></span>
            </label>
          </div>
        ))}
      </div>
    );
  };

  const closePreviewModal = () => {
    setShowFilePreview(false);
  };

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      const folderKeys = Object.keys(folders);
      setFilteredFolderKeys(
        folderKeys.filter(
          (folderKey) =>
            folderKey.toLowerCase().includes(searchText.toLowerCase()) ||
            folders[folderKey].some((file) =>
              file.toLowerCase().includes(searchText.toLowerCase())
            )
        )
      );
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, folders]);

  function PreviewModal({ isOpen, onClose, content }) {
    if (!isOpen) return null;

    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <button
            onClick={onClose}
            className="modal-close-button hover:bg-primary"
          >
            &times;
          </button>
          <div className="modal-body">{content}</div>
        </div>
      </div>
    );
  }
  const handleBreadcrumbClick = (index) => {
    setCurrentFolder(breadcrumbs[index]);
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };

  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      handleBreadcrumbClick(breadcrumbs.length - 2);
    } else if (breadcrumbs.length === 1) {
      setCurrentFolder(null);
      setBreadcrumbs([]);
    } else {
      if (view === "s3List") {
        setView("options");
      } else if (view === "tagSelection") {
        setView("s3List");
      } else if (view === "schedulingTagSelection") {
        setView("scheduling");
      } else if (view === "schedulingDatetime") {
        setView("schedulingTagSelection");
      }
    }
  };

  const handleIntegrationClick = (integration) => {
    setView("s3List");
    fetchFolders(integration);
    setIntegration(integration);
  };

  const tagDocument = async () => {
    let totalCalls = Object.keys(checkedItems).length;

    setIsActiveAction(true);

    const usedTags = {
      ...availableTags.llm.tagger_params.tag_dict,
    };

    const filteredLLMTagDict = {};
    const chooseSensitivity = checkSensitivty;

    Object.keys(usedTags).forEach((tag) => {
      if (selectedTags[tag]) {
        filteredLLMTagDict[tag] = usedTags[tag];
      }
    });

    const availableTagsCopy = objectDeepCopy(availableTags);
    availableTagsCopy.llm.tagger_params.tag_dict = filteredLLMTagDict;
    setShowModal(false);

    try {
      for (const file_name of Object.keys(checkedItems)) {
        if (
          !checkedItems[file_name].isChecked ||
          isFileContainedInArray(
            file_name,
            Object.keys({ ...catalogFiles, ...quarantinedFiles })
          )
        ) {
          totalCalls--;
          continue;
        }

        const creds = (await Auth.currentAuthenticatedUser()).username;

        const sendChunkObject = {
          data_store: JSON.stringify({
            ...preferences.webapp_profile.DATA_STORES[
              checkedItems[file_name].source
            ],
            path: `${
              preferences.webapp_profile.DATA_STORES[
                checkedItems[file_name].source
              ].base_path
            }${checkedItems[file_name].folder}${file_name}`,
          }),
          tagger_list: JSON.stringify(availableTagsCopy),
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          file_catalog_entry: JSON.stringify({ [file_name]: {} }),
          pii: pii,
          catalog_name: usedCatalog,
          quarantine_name: preferences.system.QUARANTINECATALOG,
          check_sensitivity: chooseSensitivity,
        };

        setTimeout(() => {
          sendRequest(sendChunkObject, ENDPOINTS["create_catalog"]);
        }, 10);

        // TODO: Move catalog summary and search detail update to function that checks "Processed" for catalog and then calls APIs
        // setCatalogSummary(catalogResponse.new_catalog_summary);
        // setSearchDetails(catalogResponse.search_details);
      }
    } catch (error) {
      console.log("Error during the request", error);
    } finally {
      setCheckedItems({});
      setPii(false);
      setView("options");

      setIsActiveAction(false);
    }
  };

  const scheduleTagging = async () => {
    let totalCalls = Object.keys(checkedItems).length;

    setIsActiveAction(true);

    const usedTags = {
      ...availableTags.llm.tagger_params.tag_dict,
    };

    const filteredLLMTagDict = {};
    const chooseSensitivity = checkSensitivty;

    Object.keys(usedTags).forEach((tag) => {
      if (selectedTags[tag]) {
        filteredLLMTagDict[tag] = usedTags[tag];
      }
    });

    const availableTagsCopy = objectDeepCopy(availableTags);
    availableTagsCopy.llm.tagger_params.tag_dict = filteredLLMTagDict;
    setShowModal(false);

    try {
      for (const file_name of Object.keys(checkedItems)) {
        if (
          !checkedItems[file_name].isChecked ||
          isFileContainedInArray(
            file_name,
            Object.keys({ ...quarantinedFiles })
          )
        ) {
          totalCalls--;
          continue;
        }

        const creds = (await Auth.currentAuthenticatedUser()).username;

        let endpoint;
        let params;
        if (schedulingOptions.schedulingPeriod) {
          endpoint = ENDPOINTS["schedule_periodic_catalog_creation"];
          params = {
            schedule_at: schedulingOptions.scheduleAt.toISOString(),
            period: schedulingOptions.schedulingPeriod,
          };
        } else {
          endpoint = ENDPOINTS["schedule_catalog_creation"];
          params = {
            schedule_at: schedulingOptions.scheduleAt.toISOString(),
          };
        }
        const sendChunkObject = {
          ...params,
          data_store: JSON.stringify({
            ...preferences.webapp_profile.DATA_STORES[
              checkedItems[file_name].storage_type
            ],
            path: `${checkedItems[file_name].file_directory}/${file_name}`,
          }),
          tagger_list: JSON.stringify(availableTagsCopy),
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          file_catalog_entry: JSON.stringify({ [file_name]: {} }),
          pii: pii,
          catalog_name: usedCatalog,
          quarantine_name: preferences.system.QUARANTINECATALOG,
          check_sensitivity: chooseSensitivity,
        };

        setTimeout(() => {
          sendRequest(sendChunkObject, endpoint)
            .then(
              toast.success({
                title: `Labeling scheduling at ${schedulingOptions.scheduleAt.toLocaleDateString()}`,
                description: `File: ${file_name}`,
              })
            )
            .catch(() => {
              toast.error({
                title: `Labeling scheduled at ${schedulingOptions.scheduleAt.toLocaleDateString()} failed`,
                description: `File: ${file_name}`,
              });
            });
        }, 10);
      }
    } catch (error) {
      console.log("Error during the request", error);
    } finally {
      setCheckedItems({});
      setPii(false);
      setView("options");
      setSelectedTags({});
      setIsActiveAction(false);
      setSchedulingOptions({});
    }
  };

  const fetchFolders = async (integration) => {
    let dataStore = preferences.webapp_profile.NEW_FILE_DATA_STORE;
    if (integration === "sharepoint") {
      dataStore = preferences.webapp_profile.SHAREPOINT_FILE_DATA_STORE;
    } else if (integration === "azureblob") {
      dataStore = preferences.webapp_profile.AZURE_BLOB_DATA_STORE;
    }
    setModalLoading(true);
    try {
      const folderResponse = await sendRequest(
        {
          data_store: JSON.stringify(dataStore),
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["fetch_folders"]
      );

      const retrievedFolders = await folderResponse.json();
      setFolders(retrievedFolders.folder_list);
    } catch (error) {
      console.error("Error during the request", error);
    } finally {
      setModalLoading(false);
      setView("s3List");
    }
  };

  useEffect(() => {
    if (showModal) {
      const allTagsTrue = Object.keys({
        ...availableTags.llm.tagger_params.tag_dict,
      }).reduce((acc, category) => {
        acc[category] = true;
        return acc;
      }, {});

      setSelectedTags(allTagsTrue);
    }

    const handleClickOutside = (event) => {
      if (
        listContainerRef.current &&
        !listContainerRef.current.contains(event.target)
      ) {
        setCurrentFolder(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listContainerRef]);

  const accessExistingTags = () => {
    setCurrentTag(preferences.webapp_profile.DEFAULT_TAG);
    setShowScreen(preferences.system.SENSITIVITY_TAGS_NAME);
  };

  return (
    <>
      <div className="flex justify-between bg-gradient-to-b bg-secondary w-full">
        <div className="flex items-center px-4 py-2 shrink-0 grow-0">
          <img src={Logo} className="h-[48px]" alt="LogoImageNavBar" />
        </div>
        <div className="h-full flex w-full max-w-6xl">
          <button
            onClick={() => setShowScreen("catalog")}
            className={`text-white h-full w-full whitespace-nowrap bg-primary text-sm border-t-4 transition-all ${
              showScreen === "catalog"
                ? "bg-opacity-100 border-white dark:border-b-zinc-600"
                : "border-transparent bg-opacity-40"
            }`}
          >
            Data Catalog
          </button>
          <button
            onClick={() => setShowScreen("access")}
            className={`text-white h-full w-full whitespace-nowrap bg-primary text-sm border-t-4 transition-all ${
              showScreen === "access"
                ? "bg-opacity-100 border-white dark:border-b-zinc-600"
                : "border-transparent bg-opacity-70"
            }`}
          >
            Data Quarantine
          </button>
          {permissions.usecases.canView && (
            <button
              onClick={goToUsecase}
              className={`text-white h-full w-full whitespace-nowrap bg-primary text-sm border-t-4 transition-all ${
                showScreen === "usecase"
                  ? "bg-opacity-100 border-white dark:border-b-zinc-600"
                  : "border-transparent bg-opacity-40"
              }`}
            >
              Usecase Library
            </button>
          )}
        </div>

        <div className="flex">
          <div className="dropdown-menu-parent text-sm">
            <button
              onClick={() =>
                setShowExportLogoutDropdown((prevState) => !prevState)
              }
              className="navbar-button pl-1 pr-8 bg-opacity-40 text-2xl text-white flex items-center"
              title="Export / Logout"
            >
              <FontAwesomeIcon icon={faCog} />
            </button>
            {showExportLogoutDropdown && (
              <div className="dropdown-menu">
                <button
                  onClick={() => {
                    setShowScreen("addNewTag");
                    setShowExportLogoutDropdown(false);
                    setShowTagsDropdown(false);
                    setCurrentTag({});
                  }}
                  className="bg-primary  font-semibold p-2 rounded inline-flex items-center shadow-lg text-white align-right w-full"
                >
                  <svg
                    className="-ml-1 mr-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                  Add Tag
                </button>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setShowExport(true);
                    setShowExportLogoutDropdown(false);
                    setShowTagsDropdown(false);
                  }}
                >
                  Export
                </div>
                <div
                  onClick={() => {
                    setShowScreen("preferences");
                    setShowExportLogoutDropdown(false);
                    setShowTagsDropdown(false);
                  }}
                  className="dropdown-item"
                >
                  Preferences
                </div>
                {permissions.catalogs.canEdit && (
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setShowModal(true);
                      setShowExportLogoutDropdown(false);
                      setShowTagsDropdown(false);
                      setView("options");
                    }}
                  >
                    Connect to Datasource
                  </div>
                )}
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setShowRunningTasksModal(true);
                    setShowExportLogoutDropdown(false);
                    setShowTagsDropdown(false);
                    setView("options");
                  }}
                >
                  Background Tasks
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setShowModal(true);
                    setView("scheduling");
                    setShowExportLogoutDropdown(false);
                  }}
                >
                  Schedule tagging
                </div>
                <div
                  onClick={() => {
                    setShowScreen("rules");
                    setShowExportLogoutDropdown(false);
                    setShowTagsDropdown(false);
                  }}
                  className="dropdown-item whitespace-nowrap p-4 border-b cursor-pointer hover:bg-slate-100 transition-all"
                >
                  Rules
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {
                    setShowExportLogoutDropdown(false);
                    setShowTagsDropdown(false);
                    const newTheme =
                      localStorage.getItem("theme") === "dark"
                        ? "light"
                        : "dark";
                    localStorage.setItem("theme", newTheme);
                    if (newTheme === "dark") {
                      setDarkMode(true);
                      return document.documentElement.classList.add("dark");
                    }
                    setDarkMode(false);
                    document.documentElement.classList.remove("dark");
                  }}
                >
                  {darkMode ? "Light" : "Dark"} mode
                </div>
                <div
                  className="dropdown-item"
                  onClick={(e) => {
                    Logout(e, navigate);
                    setShowExportLogoutDropdown(false);
                    setShowTagsDropdown(false);
                  }}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>

        {showRunningTasksModal && (
          <div className="Modal z-50 backdrop-blur-md">
            <div className="ModalContent">
              <span
                className="Close"
                onClick={() => {
                  setShowRunningTasksModal(false);
                }}
              >
                &times;
              </span>
              <RunningTasksModal />
            </div>
          </div>
        )}

        {showModal && (
          <div className="Modal z-50 backdrop-blur-md">
            <div className="ModalContent">
              {isModalLoading ? (
                <div className="ModalLoading">Loading...</div>
              ) : (
                <>
                  <span
                    className="Close"
                    onClick={() => {
                      setShowModal(false);
                      setCurrentFolder(null);
                      setBreadcrumbs([]);
                    }}
                  >
                    &times;
                  </span>
                  <div className="ModalHeader">
                    {view === "options" && "Connect to Data Source"}
                    {view === "s3List" && "Select Datasets"}
                    {view === "tagSelection" && "Select Tags to apply"}
                    {view === "scheduling" && "Select Documents for scheduling"}
                    {view === "schedulingTagSelection" &&
                      "Select Tags for scheduling"}
                    {view === "schedulingDatetime" && "Pick a date and time"}
                  </div>
                  {view === "scheduling" && (
                    <div className="overflow-hidden w-full flex flex-col gap-4">
                      <div className="flex w-full">
                        <div className="flex border p-2 rounded-md">
                          <div>Source:</div>
                          <select
                            className=""
                            onChange={(e) => {
                              setIntegration(e.target.value);
                            }}
                            value={integration}
                          >
                            <option value="s3">S3</option>
                            <option value="azureblob">Azure Blob</option>
                            <option value="sharepoint">Sharepoint</option>
                          </select>
                        </div>
                      </div>
                      <DocumentsSelector
                        catalogFiles={catalogFiles}
                        selectedFiles={checkedItems}
                        setCheckedItems={setCheckedItems}
                        integration={integration}
                      />
                    </div>
                  )}
                  {view === "schedulingDatetime" && (
                    <SchedulingModalContent onChange={setSchedulingOptions} />
                  )}
                  {view === "options" && (
                    <div className="ModalOptions">
                      <div
                        className="Option"
                        onClick={() => handleIntegrationClick("s3")}
                      >
                        <FontAwesomeIcon
                          icon={faAmazon}
                          className="Icon AmazonIcon"
                        />
                        Connect to S3
                      </div>
                      <div
                        className="Option"
                        onClick={() => handleIntegrationClick("sharepoint")}
                      >
                        <FontAwesomeIcon
                          icon={faMicrosoft}
                          className="Icon SharePointIcon"
                        />
                        Connect to Sharepoint
                      </div>
                      <div
                        className="Option"
                        onClick={() => handleIntegrationClick("azureblob")}
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          className="Icon SharePointIcon"
                        />
                        Connect to Azure Blob
                      </div>
                      <div className="Option Disabled">
                        <FontAwesomeIcon
                          icon={faFile}
                          className="Icon LocalIcon"
                        />
                        Connect Locally
                      </div>
                    </div>
                  )}
                  {view === "s3List" && (
                    <div className="w-full h-full overflow-hidden">
                      <div
                        className="h-full w-full overflow-hidden"
                        ref={listContainerRef}
                      >
                        <div className="w-full h-full overflow-hidden">
                          <FolderList
                            folders={folders}
                            currentFolder={currentFolder}
                            checkedItems={checkedItems}
                            searchText={searchText}
                            setCurrentFolder={setCurrentFolder}
                            setCheckedItems={setCheckedItems}
                            integration={integration}
                            setFilePreviewContent={setFilePreviewContent}
                            setShowFilePreview={setShowFilePreview}
                            filteredFolderKeys={filteredFolderKeys}
                            setSearchText={setSearchText}
                          />
                          <PreviewModal
                            isOpen={showFilePreview}
                            onClose={closePreviewModal}
                            content={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: filePreviewContent,
                                }}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {["tagSelection", "schedulingTagSelection"].includes(
                    view
                  ) && (
                    <div className="TagSelectionView">
                      <div className="FileListContainer" ref={listContainerRef}>
                        {renderTagSelection()}
                      </div>
                    </div>
                  )}
                </>
              )}
              {["s3List", "scheduling"].includes(view) && (
                <div className="ModalFooter">
                  <button onClick={handleBackClick} className="BackButton">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>{Object.keys(checkedItems).length} Files selected</p>
                  <button
                    className="RefreshButton"
                    onClick={() => {
                      if (view === "scheduling") {
                        setView("schedulingTagSelection");
                        return;
                      }
                      setView("tagSelection");
                    }}
                    disabled={Object.keys(checkedItems).length === 0}
                  >
                    Choose tags to apply
                  </button>
                </div>
              )}
              {view === "tagSelection" && (
                <div className="ModalFooter">
                  <button onClick={handleBackClick} className="BackButton">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>
                    {Object.values(selectedTags).filter(Boolean).length} Tags
                    selected
                  </p>
                  <button className="RefreshButton" onClick={tagDocument}>
                    Label your data
                  </button>
                </div>
              )}
              {view === "schedulingTagSelection" && (
                <div className="ModalFooter">
                  <button onClick={handleBackClick} className="BackButton">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>
                    {Object.values(selectedTags).filter(Boolean).length} Tags
                    selected
                  </p>
                  <button
                    className="RefreshButton"
                    onClick={() => setView("schedulingDatetime")}
                  >
                    Choose date and time
                  </button>
                </div>
              )}
              {view === "schedulingDatetime" && (
                <div className="ModalFooter">
                  <button onClick={handleBackClick} className="BackButton">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>
                    {Object.values(selectedTags).filter(Boolean).length} Tags
                    selected
                  </p>
                  <button
                    disabled={!schedulingOptions.scheduleAt}
                    className="RefreshButton"
                    onClick={scheduleTagging}
                  >
                    Schedule labeling your data
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <PreviewModal
          isOpen={showExport}
          onClose={() => setShowExport(false)}
          content={<Export onConfirm={""} />}
        />
      </div>
    </>
  );
}
