import "./EditLabel.css";
import React, { useState } from "react";

export default function EditLabel(props) {
  const handleValueChange = (index, newValue) => {
    props.setNewValue((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newValue;
      return newValues;
    });
  };

  const handleValueDelete = (index) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the label "${props.newValue[index]}"?`
    );
    if (!confirmDelete) {
      return;
    }
    props.setNewValue((prevValues) => {
      const newValues = [...prevValues];
      newValues.splice(index, 1);
      return newValues;
    });
  };
  return (
    <>
      <div className="TagReasonAndEvidence" style={{ marginBottom: "2vh" }}>
        {props.selectedLabel.tagReason !== "" && (
          <>
            <br />
            <b>{"Reason: "}</b>
            {props.selectedLabel.tagReason}
          </>
        )}
        {props.selectedLabel.tagEvidence !== "" && (
          <>
            <br />
            <b>{"Text extract: "}</b>
            {props.selectedLabel.tagEvidence}
          </>
        )}
      </div>
      <p className="Instructions">
        Edit the values below. Click "Apply" to save changes or "Delete" to
        remove a value.
      </p>
      <div
        className="ScrollContainer"
        style={{ flexDirection: "column!important" }}
      >
        {props.newValue.map((val, index) => (
          <div key={index} className="ValueEditor-edit-label">
            <select
              value={val}
              onChange={(e) => handleValueChange(index, e.target.value)}
              className="ModalSelect"
            >
              {props.catalogSummary[
                props.selectedLabel.labelKey
              ].availableValues.map((optionVal) => (
                <option value={optionVal} key={optionVal}>
                  {optionVal}
                </option>
              ))}
            </select>
            <button
              onClick={() => handleValueDelete(index)}
              className="ModalButton bg-primary DeleteButton hover:border hover:border-primary"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <button
        onClick={() =>
          props.handleLabelChange(
            props.selectedLabel.itemKey,
            props.selectedLabel.labelKey,
            props.newValue
          )
        }
        className="bg-primary rounded-md px-4 py-2 text-white"
      >
        Apply
      </button>
    </>
  );
}
