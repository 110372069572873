import React, { useContext, useState, useEffect, useMemo } from "react";
import DataFilterModal from "../../../../../../../../utilities/DataFilterModal/DataFilterModal";
import { UsecaseContext } from "../../../../../../../../../context/UsecaseContext";
import "./MetadataFilterComponent.css";
import { TagContext } from "../../../../../../../../../context/TagContext";
import { DataContext } from "../../../../../../../../../context/DataContext";

export default function MetadataFilterComponent() {
  const {
    setShowMetadataFilter,
    showMetadataFilter,
    selectedMetadata,
    setSelectedMetadata,
    confirmMetaData,
  } = useContext(UsecaseContext);

  const { availableTags } = useContext(DataContext);
  // TODO: Remove this once there is a more centralized way of getting the tag dict
  const [usecaseTagDict, setUsecaseTagDict] = useState({
    ...availableTags.llm.tagger_params.tag_dict,
    ...availableTags.sensitivity.tagger_params.tag_dict,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTags, setFilteredTags] = useState(
    usecaseTagDict ? Object.keys(usecaseTagDict) : []
  );
  const selectAll = useMemo(() => {
    return !(
      selectedMetadata.filter((metadata) => !metadata.includes(filteredTags))
        .length >= filteredTags.length
    );
  }, [filteredTags, selectedMetadata]);

  const handleSelect = (attribute) => {
    if (selectedMetadata.includes(attribute)) {
      setSelectedMetadata(
        selectedMetadata.filter((item) => item !== attribute)
      );
    } else {
      setSelectedMetadata([...selectedMetadata, attribute]);
    }
  };

  useEffect(() => {
    if (usecaseTagDict) {
      const tagValues = searchQuery
        ? Object.values(usecaseTagDict).filter((tag) =>
            tag.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : Object.values(usecaseTagDict);

      const metaNames = tagValues.map((tag) => tag.name);
      setFilteredTags(metaNames);
    }
  }, [searchQuery, usecaseTagDict]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedMetadata((prev) => [...new Set([...prev, ...filteredTags])]);
    } else if (filteredTags) {
      setSelectedMetadata([]);
    }
  };
  return (
    <div className="DataFilterComponent">
      <DataFilterModal
        isOpen={showMetadataFilter}
        onClose={() => setShowMetadataFilter(false)}
      >
        <div className="MetadataFilterContainer">
          <div className="MetadataFilterHeader">
            <div className="LeftSideAligner">
              <span>Select tags for your AI model</span>
              <input
                type="text"
                placeholder="Search tags..."
                className="MetadataSearchInput"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <button className="SelectAllButton" onClick={handleSelectAll}>
              {selectAll ? "Select All" : "Deselect All"}
            </button>
          </div>
          <div className="MetadataFilterList">
            {filteredTags &&
              usecaseTagDict &&
              filteredTags.map((attribute) => (
                <div
                  key={attribute}
                  className={`MetadataFilterItem ${
                    selectedMetadata.includes(usecaseTagDict[attribute]?.name)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleSelect(usecaseTagDict[attribute]?.name)}
                >
                  <p className="MetaDataName">
                    {usecaseTagDict[attribute]?.name}
                  </p>
                  <p className="MetaDataDescription">
                    {usecaseTagDict[attribute]?.description}
                  </p>
                </div>
              ))}
          </div>
          <div className="MetadataFilterFooter">
            <button className="ConfirmButton" onClick={confirmMetaData}>
              Confirm
            </button>
          </div>
        </div>
      </DataFilterModal>
    </div>
  );
}
