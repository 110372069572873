import React, { useContext, useMemo } from "react";
import { useAtom } from "jotai";
import { TagContext } from "../../../context/TagContext";
import { runningTasksAtom } from "../../../atoms";
import { DataContext } from "../../../context/DataContext";

const RunningTasksModal = () => {
  const [runningTasks] = useAtom(runningTasksAtom);
  const { processingTags } = useContext(TagContext);
  const { availableTags } = useContext(DataContext);
  const runningTasksArray = useMemo(() => {
    const tags = { ...availableTags.llm.tagger_params.tag_dict };
    const processTagNames = runningTasks.map((task) => ({
      categoryKey: task.categoryKey,
      aborted: task.aborted || undefined,
    }));

    const tasks = Object.values(tags)
      .filter((tag) =>
        processTagNames.map((task) => task.categoryKey).includes(tag.name)
      )
      .map((tag) => {
        const task = processTagNames.find(
          (task) => task.categoryKey === tag.name
        );
        return {
          ...tag,
          aborted: task ? task.aborted : false,
        };
      });

    tasks.forEach((item) => {
      const matchingTag = processingTags.find((tag) => tag.label === item.name);
      if (matchingTag) {
        item.isRunning = true;
      } else if (!item.aborted || item.aborted === undefined) {
        item.isRunning = false;
      }
    });
    return tasks;
  }, [availableTags, runningTasks, processingTags]);

  return (
    <div className="flex h-full w-full p-4 flex-col gap-8">
      <div className="flex justify-center gap-8">
        <table className="min-w-[30em]">
          <thead>
            <tr>
              <th colSpan="3" className="text-center text-3xl pb-12">
                Tasks Currently Running
              </th>
            </tr>
            {runningTasksArray.length === 0 ? (
              <tr>
                <th colSpan="3" className="text-center font-normal text-sm">
                  No tasks running
                </th>
              </tr>
            ) : (
              <tr>
                <th className="font-semibold">Process</th>
                <th className="font-semibold">Description</th>
                <th className="font-semibold ">Status</th>
              </tr>
            )}
          </thead>
          <tbody>
            {runningTasksArray.map((tag, index) => (
              <tr key={`${tag.name}-${index}`}>
                <td className="text-center pb-4">Tagging</td>
                <td className="text-center pb-4">{tag.name}</td>
                <td className="flex justify-center items-center pb-4">
                  {tag.isRunning ? (
                    <div className="bg-slate-200 w-32 rounded-full">
                      <p className="p-2 text-sm text-center">Running</p>
                    </div>
                  ) : tag.aborted === true ? (
                    <div className="bg-red-500 w-32 rounded-full">
                      <p className="p-2 text-sm text-white text-center">
                        Aborted
                      </p>
                    </div>
                  ) : (
                    <div className="bg-green-600 w-32 rounded-full">
                      <p className="p-2 text-sm text-white text-center">
                        Completed
                      </p>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RunningTasksModal;
