// Redesigned DataGraph component
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import Modal from "../../../../../../utilities/Modal/Modal";
import { COLOURS } from "../../../../../../../assets/colours";
import { Chart, registerables } from "chart.js";
import "./DataGraph.css";
import { calculateDistribution } from "../../../../../../utilities/functions/utils";
import { DataContext } from "../../../../../../../context/DataContext";
import twExtend from "../../../../../../../twExtend";
import { useAtom } from "jotai";
import { darkModeAtom } from "../../../../../../../atoms";
Chart.register(...registerables);

const DataGraph = (props) => {
  const { catalogSummary, currentDataGroup, isModalOpen, availableTags } =
    useContext(DataContext);

  const [data, setData] = useState({
    labels: ["No tag selected"],
    datasets: [
      {
        label: "Proportion in %",
        data: [0],
        backgroundColor: [twExtend.colors.primary],
        borderColor: [twExtend.colors.primary],
        borderWidth: 3,
      },
    ],
  });

  const [selectedCategory, setSelectedCategory] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [lastSelectedCategory, setLastSelectedCategory] = useState(null);
  const [darkMode] = useAtom(darkModeAtom);

  const toggleGraphSize = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCategoryClick = useCallback(
    (category) => {
      if (category === "chunks" || !catalogSummary || !catalogSummary[category])
        return;

      setLastSelectedCategory(category);
      const currentOptions = catalogSummary[category]?.availableValues || [];
      setSelectedCategory(category);
      const { selectedFilters } = props;
      const distribution = calculateDistribution(
        category,
        catalogSummary,
        currentDataGroup,
        selectedFilters
      );
      const { labels, data: calculatedData } = distribution || {
        labels: [],
        data: [],
      };

      const sortedIndices = calculatedData
        .map((value, index) => ({ value, index }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 15)
        .map((item) => item.index);

      const top15Labels = sortedIndices.map(
        (index) => labels[index] || "Unknown"
      );
      const top15Data = sortedIndices.map(
        (index) => calculatedData[index] || 0
      );

      setData({
        labels: top15Labels.length > 0 ? top15Labels : currentOptions,
        datasets: [
          {
            ...data.datasets[0],
            data: top15Data,
          },
        ],
      });
    },
    [catalogSummary, currentDataGroup, props]
  );

  useEffect(() => {
    if (!catalogSummary || Object.keys(catalogSummary).length === 0) {
      setData({
        labels: ["No tag selected"],
        datasets: [
          {
            label: "Proportion in %",
            data: [0],
            backgroundColor: [twExtend.colors.primary],
            borderColor: [twExtend.colors.primary],
            borderWidth: 3,
          },
        ],
      });
    } else {
      const defaultCategory =
        lastSelectedCategory || Object.keys(catalogSummary)[0];
      handleCategoryClick(defaultCategory);
    }
  }, [catalogSummary, handleCategoryClick, lastSelectedCategory]);

  return (
    <div className="h-full overflow-auto">
      <div className="flex flex-col shrink-0 grow-0 bg-zinc-100 dark:bg-zinc-800 dark:text-white rounded-md h-full">
        <div className="relative p-4 bg-slate-200 dark:bg-zinc-600 dark:text-white text-sm rounded-t-md">
          <header>Distribution of Tags</header>
          <button className="expand-button" onClick={toggleGraphSize}>
            {isExpanded ? <AiOutlineShrink /> : <AiOutlineExpandAlt />}
          </button>
        </div>
        <div className="p-2 overflow-auto flex flex-col gap-12">
          <div className="h-60">
            <Bar
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,

                scales: {
                  x: {
                    ticks: {
                      autoSkip: false,
                      maxRotation: 90,
                      minRotation: 90,
                      color: darkMode ? "white" : "black",
                    },
                    grid: {
                      display: false,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
            <Modal
              className="expanded-modal"
              isOpen={isExpanded}
              onClose={toggleGraphSize}
              title="Distribution of Tags"
            >
              <Bar
                data={data}
                className="bar-class-expanded"
                options={{
                  maintainAspectRatio: true,
                }}
              />
            </Modal>
          </div>

          <div className="flex flex-wrap px-5 gap-2">
            {catalogSummary &&
              Object.keys(catalogSummary).map(
                (category) =>
                  category !== "file_directory" &&
                  availableTags.sensitivity &&
                  !Object.keys(
                    availableTags.sensitivity.tagger_params.tag_dict
                  ).includes(category) && (
                    <div
                      key={category}
                      onClick={() => handleCategoryClick(category)}
                      className={`flex px-2 py-1 text-white rounded-md cursor-pointer text-sm hover:bg-primary ${
                        selectedCategory === category
                          ? "bg-primary"
                          : "bg-secondary"
                      }`}
                    >
                      {catalogSummary?.category
                        ? catalogSummary[category].real
                        : category}
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataGraph;
