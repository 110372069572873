import { Auth } from "aws-amplify";
import { API_USERNAME_KEYWORD } from "../../../constants/fixedValues";
import { fetchEventSource } from '@microsoft/fetch-event-source';

const backendBaseUrl =
  process.env.REACT_APP_BACKEND_BASE_URL ??
  "https://deasie-be-prod-c2731a12-zoh99u2w.onporter.run";

export async function sendRequest(
  sendObject,
  endpoint,
  method = "POST",
  timeout = 5000,
  signal = undefined
) {
  let apiResponse;
  const authUser = (await Auth.currentAuthenticatedUser()).username;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();

  if (!(API_USERNAME_KEYWORD in sendObject)) {
    apiResponse = `"${API_USERNAME_KEYWORD}" keyword missing in request, so no API request sent.`;
    return apiResponse;
  } else {
    if (sendObject[API_USERNAME_KEYWORD] !== authUser) {
      apiResponse = `"${API_USERNAME_KEYWORD}" keyword does not match the authenticated user, so no API request sent.`;
      return apiResponse;
    }
  }
  try {
    if (method === "POST") {
      apiResponse = await fetch(backendBaseUrl + endpoint, {
        method: method,
        mode: "cors",
        headers: {
          "x-user-id": authUser,
          "Content-Type": "application/json",
          "x-id-token": idToken,
          "x-access-token": accessToken,
        },
        body: JSON.stringify(sendObject),
        signal,
      });
    } else {
      apiResponse = await fetch(backendBaseUrl + endpoint, {
        method: method,
        mode: "cors",
        headers: {
          "x-user-id": authUser,
          "Content-Type": "application/json",
          "x-id-token": idToken,
          "x-access-token": accessToken,
        },
        signal,
      });
    }
  } catch (error) {
    console.error("Error during fetch:", error);
    apiResponse = null;
  }
  return apiResponse;
}

export async function listenForServerSentEvents(endpoint, onMessageCallback, controller) {
  const authUser = (await Auth.currentAuthenticatedUser()).username;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();

  const url = `${backendBaseUrl}${endpoint}`;
  fetchEventSource(url, {
    method: 'GET',
    headers: {
      "x-user-id": authUser,
      "x-id-token": idToken,
      "x-access-token": accessToken,
    },
    onmessage: (event) => {
      try {
        const data = JSON.parse(event.data);
        onMessageCallback(data);
      } catch (error) {
        console.error("Error parsing event data:", error);
      }
    },
    onerror: (error) => {
      console.error("EventSource failed:", error);
      controller.abort();
    },
    signal: controller.signal,
  });
  
}

export function getBackendBaseUrl() {
  return backendBaseUrl;
}