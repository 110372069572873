import "./Home.css";
import { useEffect, useContext } from "react";
import LoadComponent from "../../utilities/LoadComponent/LoadComponent";
import { COLOURS } from "../../../assets/colours";
import NavigationBar from "../../utilities/NavigationBar/NavigationBar";
import DataCatalog from "./HomeComponents/DataCatalog/DataCatalog";
import UseCaseLibrary from "./HomeComponents/UseCaseLibrary/UseCaseLibrary";
import ChatInterface from "./HomeComponents/ChatInterface/ChatInterface";
import AccessControl from "./HomeComponents/AccessControls/AccessControls/AccessControls";
import AddNewTag from "./HomeComponents/AddNewTag/AddNewTag";
import RulesScreen from "./HomeComponents/Rules/Rules";
import Tags from "./HomeComponents/DataCatalog/DataCatalogComponents/Tags/Tags";
import { DataContext } from "../../../context/DataContext";
import UserPreferences from "./HomeComponents/Preferences/Preferences";

import { countStatusValues } from "../../utilities/functions/utils";
import { TagContext } from "../../../context/TagContext";

function Home() {
  const {
    isLoading,
    showScreen,
    // Functions
    fetchInitialCatalog,
    fetchInitialTaggerList,
    fetchInitialUsecases,
    preferences,
    catalogFiles,
    setCurrentProcessCount,
    setCurrentDataGroup,
  } = useContext(DataContext);

  const { prevCount } = useContext(TagContext);
  useEffect(() => {
    fetchInitialCatalog();
    fetchInitialUsecases();
    fetchInitialTaggerList();
  }, []);

  useEffect(() => {
    const newCount = countStatusValues(catalogFiles, "Processed").Processed;

    const update = prevCount + newCount;
    setCurrentProcessCount(update);
  }, [catalogFiles]);

  if (isLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="Home" style={{ backgroundColor: COLOURS["subText"] }}>
      {showScreen !== "chat" && <NavigationBar />}
      <div className="p-4 bg-slate-300 dark:bg-black h-full overflow-hidden flex justify-center items-center w-full">
        {showScreen === "catalog" ? (
          <DataCatalog />
        ) : showScreen === "usecase" ? (
          <UseCaseLibrary />
        ) : showScreen === "access" ? (
          <AccessControl />
        ) : showScreen === "addNewTag" ? (
          <AddNewTag />
        ) : showScreen === "rules" ? (
          <RulesScreen />
        ) : showScreen === "preferences" ? (
          <UserPreferences />
        ) : showScreen === preferences.system.SENSITIVITY_TAGS_NAME ? (
          <div className="home-label-parent-container w-full max-w-6xl">
            <p className="home-label-title">Existing Tags</p>
            <div className="home-label-container">
              <Tags title={"Classification Tags"} tagTypes={["llm"]} />
              <Tags title={"Sensitivity Tags"} tagTypes={["sensitivity"]} />
            </div>
          </div>
        ) : (
          <ChatInterface />
        )}
      </div>
    </div>
  );
}

export default Home;
