import React, { useState } from "react";
import "./Standardization.css";
import MappingList from "./StandardizationComponents/MappingList/MappingList";
import SensitivitySettings from "./StandardizationComponents/Sensitivity/Sensitivity";
const Standardization = ({ mappings, setMappings, ...props }) => {
  return (
    <>
      {props.showStandardization && (
        <div className="modal-mapping-list">
          <div className="modal-content-mapping-list">
            <span
              className="close-mapping-list"
              onClick={() =>
                props.setShowStandardization((prevState) => !prevState) &
                setMappings({}) &
                props.setStandardizeScreen("config") &
                props.setStandardizeTag("") &
                props.setStandardSensitivity(0.5) &
                props.setStandardizeMinCategories(3)
              }
            >
              &times;
            </span>
            {props.standardizeScreen === "config" && (
              <SensitivitySettings
                setStandardSensitivity={props.setStandardSensitivity}
                setStandardizeScreen={props.setStandardizeScreen}
                runStandardization={props.runStandardization}
                standardizeMinCategories={props.standardizeMinCategories}
                setStandardizeMinCategories={props.setStandardizeMinCategories}
              />
            )}
            {props.standardizeScreen === "mappings" && (
              <MappingList
                mappings={mappings}
                setMappings={setMappings}
                acceptNewValues={props.acceptNewValues}
                standardizeTag={props.standardizeTag}
                setStandardizeTag={props.setStandardizeTag}
                setStandardizeScreen={props.setStandardizeScreen}
                standardizationLoading={props.standardizationLoading}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Standardization;
