import { Backdrop, CircularProgress } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { getPreferences } from "./../components/utilities/functions/apiCalls";

const UserProfileContext = createContext(null);
UserProfileContext.displayName = "UserProfileContext";

/**
 * @typedef {Object} PermissionFlags
 * @property {boolean} canView
 * @property {boolean} canEdit
 */

/**
 * @typedef {Object} Permissions
 * @property {PermissionFlags} tags
 * @property {PermissionFlags} catalogs
 * @property {PermissionFlags} usecases
 */

export const UserProfileProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    getPreferences().then((profile) => {
      setUserProfile({
        ...profile,
        /**
         * @type {Permissions}
         */
        permissions: {
          tags: {
            canView: profile.permissions.tags.can_view,
            canEdit: profile.permissions.tags.can_edit,
          },
          catalogs: {
            canView: profile.permissions.catalogs.can_view,
            canEdit: profile.permissions.catalogs.can_edit,
          },
          usecases: {
            canView: profile.permissions.usecases.can_view,
            canEdit: profile.permissions.usecases.can_edit,
          },
        },
      });
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <UserProfileContext.Provider value={userProfile}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => useContext(UserProfileContext);
