import "./Sensitivity.css";
import { useState } from "react";

const SensitivitySettings = (props) => {
  const [activeButton, setActiveButton] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const handleClick = (sensitivity) => {
    props.setStandardSensitivity(sensitivity);
    setActiveButton(sensitivity);
  };

  const handleRunStandardization = () => {
    const minCategories = parseInt(props.standardizeMinCategories, 10);
    if (minCategories > 20) {
      setErrorMsg("Minimum categories cannot exceed 20.");
      return;
    }
    if (activeButton === null) {
      setErrorMsg("Please select a standardization sensitivity.");
      return;
    }
    setErrorMsg("");
    props.runStandardization();
  };

  return (
    <div className="sensitivity-settings">
      <h4 className="sensitivity-header">Choose Standardization Sensitivity</h4>
      <div className="buttons-container">
        {["Loose", "Moderate", "Strict"].map((type, index) => {
          const sensitivityValue = [0.2, 0.5, 0.8][index];
          const descriptions = [
            "Standardizes values liberally, creating general and less categories.",
            "Strikes a balance between grouping and diversity of values.",
            "Creates more specific groups, retaining a high diversity of values.",
          ];
          return (
            <div className="sensitivity-option" key={type}>
              <button
                className={`sensitivity-button ${
                  activeButton === sensitivityValue ? "active" : ""
                }`}
                onClick={() => handleClick(sensitivityValue)}
              >
                {`${type} Grouping`}
              </button>
              <p>{descriptions[index]}</p>
            </div>
          );
        })}
      </div>
      <div className="min-categories" style={{ marginBottom: "4vh" }}>
        <label htmlFor="min-categories-input">
          Minimum Categories: {props.standardizeMinCategories}
        </label>
        <input
          type="range"
          id="min-categories-input"
          min="1"
          max="20"
          value={props.standardizeMinCategories}
          onChange={(e) => props.setStandardizeMinCategories(e.target.value)}
        />
      </div>
      <p className="error-message">{errorMsg}</p>
      <button
        className="run-standardization-button"
        onClick={handleRunStandardization}
        disabled={activeButton === null}
      >
        Run Standardization
      </button>
    </div>
  );
};

export default SensitivitySettings;
