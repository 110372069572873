import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

import Home from "./components/pages/Home/Home";
import Catalog from "./pages/Catalog";
import { DataProvider } from "./context/DataContext";
import { TagProvider } from "./context/TagContext";
import { UsecaseProvider } from "./context/UsecaseContext";
import { ChatProvider } from "./context/ChatContext";
import { ToastContainer } from "./components/utilities/Toast";
import { UserProfileProvider } from "./context/UserProfile";
import RunningTasksSpinner from "./components/utilities/RunningTasksSpinner";

export default function App() {
  const [ready, setIsReady] = useState(false);

  useState(() => {
    setIsReady(true);

    if (localStorage.getItem("theme") !== "dark") return;
    document.documentElement.classList.add("dark");
  });

  if (!ready) return <></>;

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <UserProfileProvider>
          <DataProvider>
            <TagProvider>
              <UsecaseProvider>
                <ChatProvider>
                  <div>
                    <ToastContainer />
                    <RunningTasksSpinner />
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/catalog" element={<Catalog />} />
                    </Routes>
                  </div>
                </ChatProvider>
              </UsecaseProvider>
            </TagProvider>
          </DataProvider>
        </UserProfileProvider>
      )}
    </Authenticator>
  );
}
