import { useState, useCallback } from "react";
import { FileCheckbox } from "../FileCheckbox";

export const DocumentsSelector = ({
  catalogFiles,
  selectedFiles,
  setCheckedItems,
  integration,
}) => {
  const toggleDocument = useCallback(
    (documentName) => {
      setCheckedItems((old) => {
        const isChecked = !(old[documentName]?.isChecked ?? false);
        if (!isChecked && old[documentName]) {
          const { [documentName]: removedFile, ...rest } = old;
          return rest;
        }

        return {
          ...old,
          [documentName]: {
            isChecked: true,
            storage_type: catalogFiles[documentName].storage_type,
            file_directory: catalogFiles[documentName].file_directory,
          },
        };
      });
    },
    [setCheckedItems]
  );

  return (
    <div className="w-full h-full overflow-hidden">
      <ul className="h-full overflow-y-auto">
        {Object.entries(catalogFiles)
          .filter(([name, { storage_type }]) => {
            return storage_type?.[0] === integration;
          })
          .map(([documentName]) => {
            return (
              <li
                className="p-2 flex gap-2 items-center border-b border-x justify-between md:flex-nowrap flex-wrap break-all "
                key={documentName}
              >
                <FileCheckbox
                  fileName={documentName}
                  isSelected={Object.keys(selectedFiles).includes(documentName)}
                  onClick={() => toggleDocument(documentName)}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};
