import React, { useContext, useState } from "react";
import { DataContext } from "../../../context/DataContext";
import { useUserProfile } from "../../../context/UserProfile";
import { addNewCatalog } from "../../utilities/functions/apiCalls";
import { toast } from "../../utilities/Toast";

import "./SearchBar.css";

export default function SearchBar() {
  const {
    setSearchTerm,
    catalogNames,
    usedCatalog,
    setUsedCatalog,
    fetchInitialCatalog,
    fetchInitialTaggerList,
  } = useContext(DataContext);
  const isLoading = !catalogNames || catalogNames.length === 0;
  const { permissions } = useUserProfile();

  const handleCatalogChange = async (event) => {
    const value = event.target.value;
    if (value === "newCatalog") {
      const catalogName = prompt("Enter new catalog name:");
      if (catalogName) {
        const result = await addNewCatalog(catalogName);
        if (result) {
          setUsedCatalog(catalogName);
          toast.success({
            title: "Success",
            description: "Catalog created successfully!",
          });
        } else {
          toast.error({
            title: "Error",
            description: "Failed to create catalog.",
          });
        }
      }
    } else {
      toast.info({
        title: "Info",
        description: "Loading catalog " + value + "!",
      });
      setUsedCatalog(value);
    }
    await fetchInitialCatalog(value);
    await fetchInitialTaggerList(value);
    toast.success({
      title: "Success",
      description: "Catalog loaded successfully!",
    });
  };

  return (
    <div className="w-full flex-flex-col rounded-t-md overflow-hidden bg-slate-200 dark:bg-zinc-600">
      <div className="flex w-full outline-none dark:text-white">
        <div className="flex w-full flex-col">
          <div className="text-sm bg-slate-200 dark:bg-zinc-600 p-4">
            <div className="catalog-selection">
              Catalog:
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <select
                  onChange={handleCatalogChange}
                  value={usedCatalog || ""}
                >
                  <option value="" disabled>
                    Select a catalog
                  </option>
                  {catalogNames.map((catalog, index) => (
                    <option key={index} value={catalog}>
                      {catalog}
                    </option>
                  ))}
                  {permissions.catalogs.canEdit && (
                    <option value="newCatalog">Create New Catalog</option>
                  )}
                </select>
              )}
            </div>
          </div>
          <input
            type="text"
            className={`block w-full bg-white dark:bg-zinc-800 text-sm dark:text-white bg-transparent p-4 dark:rounded-none focus:outline-none`}
            placeholder={"Search for a document"}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
