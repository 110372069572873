import { Auth } from "aws-amplify";

export const Logout = async (e, navigate) => {
  e.preventDefault();
  await Auth.signOut()
    .then(() => {
      navigate("/");
    })
    .catch((error) => {
      console.log(error.code);
      console.log(error.message);
    });
};
