import { COLOURS } from "../colours";

export const specificButtonStyles = {
  hidden: {
    pointerEvents: "none",
    opacity: "0.4",
    backgroundColor: COLOURS["SubText"],
  },
  normal: {
    pointerEvents: "all",
    opacity: "1",
    backgroundColor: COLOURS["SubText"],
  },
};
