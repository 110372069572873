import React, { useContext } from "react";
import "./UseCaseLibrary.css";
import { COLOURS } from "../../../../../assets/colours";
import { faTrash, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { Auth } from "aws-amplify";
import { DataContext } from "../../../../../context/DataContext";
import { UsecaseContext } from "../../../../../context/UsecaseContext";
import { useUserProfile } from "../../../../../context/UserProfile";

export default function UseCaseLibraryMenu() {
  const {
    useCases,
    setShowScreen,
    setUsecaseSelected,
    setUseCases,
    preferences,
  } = useContext(DataContext);
  const { permissions } = useUserProfile();

  const { editUseCase, setUsecaseStage, setCurrentUseCase } =
    useContext(UsecaseContext);

  const goToChat = (index) => {
    setUsecaseSelected(useCases[index]);
    setShowScreen("chat");
  };

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to delete usecase ${id}?`,
    );
    if (userConfirmed) {
      const deleteUsecase = useCases.filter((item) => item.id === id)[0];
      await sendRequest(
        {
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
          usecase_name: id,
          usecase_files: Object.keys(deleteUsecase.usecase_catalog),
        },
        ENDPOINTS["delete_usecase"],
      );

      const filteredItems = useCases.filter((item) => item.id !== id);
      setUseCases(filteredItems);
    }
  };

  const goToUsecaseDefinition = () => {
    setUsecaseStage("usecase-definition");
    setCurrentUseCase(preferences.webapp_profile.BASE_USECASE);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 usecase-container">
      <div className="mt-8 flow-root usecase-table dark:bg-zinc-600 dark:text-white">
        <div>
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th className="usecase-cell-col usecase-cell">ID</th>
                  <th className="usecase-cell-col usecase-cell">Name</th>
                  <th className="usecase-cell-col usecase-cell">
                    Usecase Type
                  </th>
                  <th className="usecase-cell-col usecase-cell">
                    Usecase Description
                  </th>

                  <th className="dataset-cell usecase-cell">PII</th>
                  {/* <th className="dataset-cell usecase-cell">Access Groups</th> */}
                  <th className="usecase-cell-col usecase-cell">Metadata</th>
                  <th className="time-validity-cell usecase-cell">
                    # of Datasets
                  </th>

                  <th className="edit-cell usecase-cell">
                    <span></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {useCases &&
                  useCases.map((usecase, idx) => (
                    <tr key={idx}>
                      <td
                        className="usecase-cell-col usecase-cell"
                        title={usecase.id}
                      >
                        {usecase.id}
                      </td>
                      <td
                        className="usecase-cell-col usecase-cell"
                        title={usecase.name || ""}
                      >
                        {usecase.name || ""}
                      </td>
                      <td
                        className="usecase-cell-col usecase-cell"
                        title={usecase.type}
                      >
                        {usecase.type}
                      </td>
                      <td
                        className="usecase-cell-col usecase-cell"
                        title={usecase.description}
                      >
                        {usecase.description}
                      </td>
                      <td
                        className="usecase-cell-col usecase-cell"
                        title={usecase.pii ? "Yes" : "No"}
                      >
                        {usecase.pii ? "Yes" : "No"}
                      </td>
                      {/* <td className="dataset-cell usecase-cell">
                        {Array.isArray(usecase.access_group)
                          ? usecase.access_group.join(", ")
                          : usecase.access_group}
                      </td> */}
                      <td className="dataset-cell usecase-cell">
                        {usecase.metadata && usecase.metadata.length !== 0
                          ? Array.isArray(usecase.metadata)
                            ? usecase.metadata.join(", ")
                            : usecase.metadata
                          : "No Metadata selected"}
                      </td>

                      <td className="time-validity-cell usecase-cell">
                        {usecase.usecase_catalog
                          ? Object.keys(usecase.usecase_catalog).length
                          : 0}
                      </td>
                      <td className="usecase-cell-col usecase-cell">
                        <button
                          className="activate-api-btn bg-primary"
                          onClick={() => goToChat(idx)}
                          disabled={!usecase.usecase_created}
                        >
                          {!usecase.usecase_created
                            ? "Processing usecase "
                            : "Ask your data"}
                        </button>
                        {permissions.usecases.canEdit && (
                          <>
                            <button
                              className="edit-button-usecase"
                              onClick={() => editUseCase(usecase.id)}
                            >
                              <FontAwesomeIcon
                                icon={faCog}
                                className="TrashIconUsecase"
                                onClick={() => editUseCase(usecase.id)}
                                style={{ color: COLOURS["MainText"] }}
                              />
                            </button>
                            <button
                              className="delete-button"
                              onClick={() => handleDelete(usecase.id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="TrashIconUsecase"
                                onClick={() => handleDelete(usecase.id)}
                                style={{ color: COLOURS["MainText"] }}
                              />
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="sm:flex sm:items-center ">
        <div className="sm:flex-auto"></div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {permissions.usecases.canEdit && (
            <button
              type="button"
              className="bg-primary addusecase-button block rounded-md px-3 py-2 text-center text-white text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={goToUsecaseDefinition}
            >
              Define data for usecase
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
