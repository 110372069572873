import React, { createContext, useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";

import { API } from "@aws-amplify/api";
import { onTableUpdate } from "../components/utilities/GraphQL/subscriptions";
import { updateCatalog } from "../components/utilities/functions/apiCalls";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { LABELS_TO_EXCLUDE_FOR_SEARCH } from "../constants/labelConfig";

import {
  deleteLabelParentLabelObj,
  mergeTagWithDefaults,
  updateModelVersion,
} from "../components/utilities/functions/utils";
import { toast } from "../components/utilities/Toast";
import { Queue } from "../utils";
import { useFailedTags } from "../hooks/FailedTags";
import { FETCH_DATA_SUBSCRIPTION } from "../constants/fixedValues";
import { useUserProfile } from "./UserProfile";
const { WebPubSubClient } = require("@azure/web-pubsub-client");

export const DataContext = createContext();

export const defaultCurrentTag = {
  name: "",
  tagType: "classification",
  description: "",
  availableValues: [],
  allow_other_values: false,
  reference_file: "",
  max_words: 1,
  examples: null,
  option: "aiGenerated",
  risk_level: "",
  risk_level_description: {
    low: "",
    medium: "",
    high: "",
  },
  type: "word",
};

export const DataProvider = ({ children }) => {
  const [preferences, setPreferences] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showScreen, setShowScreen] = useState("catalog");
  const [usedCatalog, setUsedCatalog] = useState({});
  const [catalogSummary, setCatalogSummary] = useState({});
  const [dataGroups, setDataGroups] = useState({});
  const [catalogFiles, setCatalogFiles] = useState({});
  const [currentDataGroup, setCurrentDataGroup] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [detectedDataGroup, setDetectedGroup] = useState({});
  const [useCases, setUseCases] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [usecaseSelected, setUsecaseSelected] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(-1);
  const [availableTags, setAvailableTags] = useState({});
  const [availableRules, setAvailableRules] = useState({});
  const [searchDetails, setSearchDetails] = useState({});
  const [quarantinedFiles, setQuarantinedFiles] = useState({});
  const [processingFile, setProcessingFile] = useState(-1);
  // TODO: change the current reference to DEFAULT_TAG parameter
  const [currentTag, setCurrentTag] = useState({
    name: "",
    tagType: "classification",
    description: "",
    availableValues: [],
    allow_other_values: false,
    reference_file: "",
    max_words: 1,
    examples: null,
    option: "aiGenerated",
    risk_level: "",
    risk_level_description: {
      low: "",
      medium: "",
      high: "",
    },
    type: "word",
  });
  const [currentProcessCount, setCurrentProcessCount] = useState(0);
  const [currentTotalProcessCount, setCurrentTotalProcessCount] = useState(0);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [startPoint, setStartPoint] = useState(0);
  const deleteTagsQueueRef = useRef(new Queue());
  const [tagsToBeDeleted, setTagsToBeDeleted] = useState([]);
  const [catalogNames, setCatalogNames] = useState([]);
  const { failedTags, tagReRun, hasTagFailedFor, failedTagDeleted } =
    useFailedTags(currentDataGroup);

  const subscriptionRefs = useRef({ catalog: null, quarantine: null });
  const userProfile = useUserProfile();

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        setPreferences(userProfile);
        setAvailableTags(userProfile.system.TAGGER_LIST);
        setUsedCatalog(userProfile.system.EXISTING_CATALOG);
        const hiddenTags = userProfile.hidden_tags.HIDDEN_TAGS;
        setHiddenCategories([...hiddenCategories, ...hiddenTags]);
      } catch (error) {
        console.error("Error fetching preferences:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPreferences();
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchAuthUser = async () => {
      try {
        return (await Auth.currentAuthenticatedUser()).username;
      } catch (error) {
        console.error("Authentication error:", error);
      }
    };

    const subscribeToAWSAppSync = async () => {
      const user = await fetchAuthUser();
      const catalogSubscription = API.graphql({
        query: onTableUpdate,
        variables: {
          username_catalog_name: `${user}-${usedCatalog}`,
        },
      }).subscribe({
        next: (eventData) => {
          if (!isSubscribed) return;
          const file_name = eventData.value.data.onTableUpdate.file_name;
          const file_entry = JSON.parse(
            eventData.value.data.onTableUpdate.file_entry
          );

          setCatalogFiles((prevCatalogFiles) => ({
            ...prevCatalogFiles,
            [file_name]: file_entry,
          }));

          setCurrentDataGroup((prevDataGroup) => ({
            ...prevDataGroup,
            [file_name]: file_entry,
          }));
        },
      });

      const quarantineSubscription = API.graphql({
        query: onTableUpdate,
        variables: {
          username_catalog_name: `${user}-${preferences.system.QUARANTINECATALOG}`,
        },
      }).subscribe({
        next: (eventData) => {
          if (!isSubscribed) return;
          const file_name = eventData.value.data.onTableUpdate.file_name;
          const file_entry = JSON.parse(
            eventData.value.data.onTableUpdate.file_entry
          );

          setQuarantinedFiles((prevQuarantinedFiles) => ({
            ...prevQuarantinedFiles,
            [file_name]: file_entry,
          }));
        },
        error: (error) => console.error(error),
      });

      subscriptionRefs.current.catalog = catalogSubscription;
      subscriptionRefs.current.quarantine = quarantineSubscription;
    };

    const subscribeToAzurePubSub = async () => {
      const user = await fetchAuthUser();
      const client = new WebPubSubClient({
        getClientAccessUrl: async () => {
          let value = await (
            await sendRequest(
              {
                [preferences.system.API_USERNAME_KEYWORD]: user,
              },
              ENDPOINTS["pubsub_token"],
              "GET"
            )
          ).json();
          return value.url;
        },
      });

      await client.start();
      client.on("connected", (e) => { });

      await client.joinGroup(`${user}-${usedCatalog}`);
      await client.joinGroup(`${user}-${preferences.system.QUARANTINECATALOG}`);

      client.on("group-message", (e) => {
        if (!isSubscribed) return;
        const eventData = JSON.parse(e.message.data);
        const username_catalog_name = eventData["username_catalog_name"];
        const file_name = eventData["file_name"];
        const file_entry = JSON.parse(eventData["file_entry"]);

        if (username_catalog_name === `${user}-${usedCatalog}`) {
          setCatalogFiles((prevCatalogFiles) => ({
            ...prevCatalogFiles,
            [file_name]: file_entry,
          }));
          setCurrentDataGroup((prevDataGroup) => ({
            ...prevDataGroup,
            [file_name]: file_entry,
          }));
        } else if (
          username_catalog_name ===
          `${user}-${preferences.system.QUARANTINECATALOG}`
        ) {
          setQuarantinedFiles((prevQuarantinedFiles) => ({
            ...prevQuarantinedFiles,
            [file_name]: file_entry,
          }));
        }
      });

      subscriptionRefs.current.catalog = { unsubscribe: () => client.stop() };
      subscriptionRefs.current.quarantine = { unsubscribe: () => { } };
    };

    const fetchDataSubscription = async () => {
      if (FETCH_DATA_SUBSCRIPTION === "AWSAPPSYNC") {
        await subscribeToAWSAppSync();
      } else if (FETCH_DATA_SUBSCRIPTION === "AZUREPUBSUB") {
        await subscribeToAzurePubSub();
      }
    };

    if (isLoading) return;
    fetchDataSubscription();

    return () => {
      console.log("Unsubscribing from subscriptions");
      isSubscribed = false;
      Object.values(subscriptionRefs.current).forEach(
        (sub) => sub?.unsubscribe?.() || sub?.close?.()
      );
      subscriptionRefs.current = { catalog: null, quarantine: null };
    };
  }, [usedCatalog]);

  const handleLabelChange = async (itemKey, labelKey, newValue) => {
    const afterLabelChange = (prevState) => ({
      ...prevState,
      [itemKey]: {
        ...prevState[itemKey],
        [labelKey]: newValue,
      },
    });
    setCurrentDataGroup(afterLabelChange);
    setCatalogFiles(afterLabelChange);

    await updateCatalog(usedCatalog, afterLabelChange(catalogFiles));
    setModalOpen(false);
  };

  const editTag = (e, name) => {
    e.stopPropagation();

    const selectedTag = mergeTagWithDefaults(
      {
        ...availableTags.llm.tagger_params.tag_dict,
        ...availableTags.sensitivity.tagger_params.tag_dict,
      }[name],
      defaultCurrentTag
    );

    setCurrentTag(selectedTag);
    setShowScreen("addNewTag");
  };

  const handleLabelDelete = async (itemKey, labelKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the label "${labelKey}"?`
    );
    if (!confirmDelete) {
      return;
    }

    const afterLabelDelete = (prevState) => {
      const updatedItem = { ...prevState[itemKey] };
      delete updatedItem[labelKey];
      return { ...prevState, [itemKey]: updatedItem };
    };

    setCurrentDataGroup(afterLabelDelete);
    setCatalogFiles(afterLabelDelete);

    setModalOpen(false);
  };

  const deleteAllLabel = async (e, tagName) => {
    e.stopPropagation();
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the label "${tagName}"?`
    );

    if (!confirmDelete) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing tag ${tagName} to be deleted`,
    });

    setTagsToBeDeleted((prev) => [...prev, tagName]);

    try {
      deleteTagsQueueRef.current.addNewTask(async () => {
        const NEW_TAGGER_LIST = deleteLabelParentLabelObj(
          availableTags,
          tagName
        );

        const rawResponse = await sendRequest(
          {
            catalog: JSON.stringify(catalogFiles),
            [preferences.system.API_USERNAME_KEYWORD]: (
              await Auth.currentAuthenticatedUser()
            ).username,
            label: tagName,
            catalog_name: usedCatalog,
            new_tagger_list: JSON.stringify(NEW_TAGGER_LIST),
          },
          ENDPOINTS["delete_label"]
        );
        const response = await rawResponse.json();

        await new Promise((res, rej) => setTimeout(res, 5000));

        toast.success({
          title: "Success",
          description: `Tag ${tagName} successfully deleted`,
        });

        setAvailableTags(NEW_TAGGER_LIST);
        setTagsToBeDeleted((prev) => prev.filter((tag) => tag !== tagName));
        failedTagDeleted(tagName);

        return response;
      });

      deleteTagsQueueRef.current.onQueueFinished = (response) => {
        setCatalogFiles(response.catalog);
        setCatalogSummary(response.catalog_summary);
        setCurrentDataGroup(response.catalog);
      };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDatasetDelete = async (itemKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the dataset "${itemKey}"?`
    );
    toast.info({
      title: "Info",
      description: `Preparing dataset ${itemKey} to be deleted`,
    });
    if (!confirmDelete) {
      return;
    }

    try {
      const afterDataDelete = (prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[itemKey];
        return updatedState;
      };

      setCurrentDataGroup(afterDataDelete);
      setCatalogFiles(afterDataDelete);

      await updateCatalog(usedCatalog, afterDataDelete(catalogFiles));
      toast.success({
        title: "Success",
        description: `Successfully deleted dataset ${itemKey}`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error happened trying to delete your dataset ${itemKey}: ${String(
          error
        )}`,
      });
    }
  };

  const handleQuarantineDataDelete = async (itemKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the dataset "${itemKey}"?`
    );

    if (!confirmDelete) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing dataset ${itemKey} to be deleted`,
    });

    try {
      setQuarantinedFiles((prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[itemKey];
        return updatedState;
      });

      const updatedCatalog = afterDataDelete(quarantinedFiles);
      await updateCatalog(preferences.system.QUARANTINECATALOG, updatedCatalog);

      toast.success({
        title: "Success",
        description: `Successfully deleted dataset ${itemKey} from quarantine`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error happened trying to delete your dataset ${itemKey}: ${String(
          error
        )}`,
      });
    }
  };

  const afterDataDelete = (prevState, itemKey) => {
    const updatedState = { ...prevState };
    delete updatedState[itemKey];
    return updatedState;
  };

  const deleteMultipleQuarantine = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the quarantine?`
      )
    ) {
      return;
    }

    const newDataGroup = { ...quarantinedFiles };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      setCatalogFiles(newDataGroup);
      setCurrentDataGroup(newDataGroup);
      await updateCatalog(preferences.system.QUARANTINECATALOG, newDataGroup);

      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error
        )}`,
      });
    }
  };

  const deleteMultipleDatasets = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the catalog?`
      )
    ) {
      return;
    }

    const newDataGroup = { ...currentDataGroup };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      setCatalogFiles(newDataGroup);
      setCurrentDataGroup(newDataGroup);
      await updateCatalog(usedCatalog, newDataGroup);

      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error
        )}`,
      });
    }
  };

  const handleMultipleDelete = () => {
    const allKeys = Object.keys(currentDataGroup);
    deleteMultipleDatasets(allKeys);
    setSelectedFilters({});
  };

  const fetchInitialCatalog = async (catalog_name = null) => {
    try {
      const rawResponseSafe = await sendRequest(
        {
          catalog_name: catalog_name || usedCatalog,
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["get_catalog"]
      );
      const SafeFiles = await rawResponseSafe.json();

      const rawResponseUnsafe = await sendRequest(
        {
          catalog_name: preferences.system.QUARANTINECATALOG,
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["get_catalog"]
      );
      const UnsafeFiles = await rawResponseUnsafe.json();

      setCatalogNames(SafeFiles.catalog_names);
      setCatalogFiles(SafeFiles.catalog);
      setCatalogSummary(SafeFiles.filter_map);
      setCurrentDataGroup(SafeFiles.catalog);
      setSearchDetails(SafeFiles.search_details);
      setQuarantinedFiles(UnsafeFiles.catalog);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateTagDict = (newTagDict) => {
    const cleanedTagDict = Object.keys(newTagDict)
      .filter((key) => !LABELS_TO_EXCLUDE_FOR_SEARCH.includes(key))
      .reduce((obj, key) => {
        obj[key] = availableTags.llm.tagger_params.tag_dict[key];
        return obj;
      }, {});

    setAvailableTags((prevState) => ({
      ...prevState,
      llm: {
        ...prevState.llm,
        tagger_params: {
          ...prevState.llm.tagger_params,
          tag_dict: cleanedTagDict,
        },
      },
    }));
  };

  const fetchInitialTaggerList = async (catalog_name = null) => {
    const rawResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: catalog_name || usedCatalog,
      },
      ENDPOINTS["get_tags"]
    );
    const response = await rawResponse.json();
    if (Object.keys(response.tags).length > 0) {
      let updatedTaggers = updateModelVersion(
        response.tags,
        preferences.webapp_profile.MODEL_USED,
        preferences.webapp_profile.PROVIDER_USED
      );

      if (!updatedTaggers.sensitivity) {
        updatedTaggers = {
          ...updatedTaggers,
          sensitivity: preferences.system.SENSITIVITY_DEFAULT,
        };
      }
      setAvailableTags(updatedTaggers);
    } else {
      setAvailableTags(preferences.system.TAGGER_LIST);
    }
  };

  const fetchInitialUsecases = async () => {
    try {
      const rawResponse = await sendRequest(
        {
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["get_all_usecases"]
      );
      const response = await rawResponse.json();
      setUseCases(response.usecases);
    } catch (error) {
      setUseCases({});
      console.error("Error fetching data:", error);
    }
  };

  const toggleCategoryVisibility = (e, category) => {
    e.stopPropagation();
    if (hiddenCategories.includes(category)) {
      setHiddenCategories(hiddenCategories.filter((c) => c !== category));
      toast.success({
        title: "Success",
        description: `Category ${category} successfully unhidden`,
      });
    } else {
      setHiddenCategories([...hiddenCategories, category]);
      toast.success({
        title: "Success",
        description: `Category ${category} successfully hidden`,
      });
    }
  };

  if (isLoading) {
    return <div>Loading preferences...</div>;
  }

  return (
    <DataContext.Provider
      value={{
        // Getters
        isLoading,
        showScreen,
        usedCatalog,
        catalogSummary,
        dataGroups,
        catalogFiles,
        currentDataGroup,
        searchTerm,
        detectedDataGroup,
        useCases,
        usecaseSelected,
        isModalOpen,
        fileUploadProgress,
        availableTags,
        availableRules,
        searchDetails,
        quarantinedFiles,
        processingFile,
        currentTag,
        currentProcessCount,
        currentTotalProcessCount,
        dateRange,
        hiddenCategories,
        selectedFilters,
        preferences,
        startPoint,
        tagsToBeDeleted,
        failedTags,
        catalogNames,
        // Setters
        setHiddenCategories,
        setStartPoint,
        setIsLoading,
        setShowScreen,
        setUsedCatalog,
        setCatalogSummary,
        setDataGroups,
        setCatalogFiles,
        setCurrentDataGroup,
        setSearchTerm,
        setDetectedGroup,
        setUseCases,
        setDateRange,
        setUsecaseSelected,
        setModalOpen,
        setFileUploadProgress,
        setAvailableTags,
        setAvailableRules,
        setSearchDetails,
        setQuarantinedFiles,
        setProcessingFile,
        setCurrentTag,
        setCurrentProcessCount,
        setCurrentTotalProcessCount,
        setSelectedFilters,
        setPreferences,
        tagReRun,
        hasTagFailedFor,
        // Functions
        handleLabelChange,
        editTag,
        handleLabelDelete,
        deleteAllLabel,
        handleDatasetDelete,
        updateTagDict,
        fetchInitialCatalog,
        fetchInitialTaggerList,
        fetchInitialUsecases,
        toggleCategoryVisibility,
        handleMultipleDelete,
        handleQuarantineDataDelete,
        deleteMultipleQuarantine,
        setCatalogNames,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
