export class Queue {
  tasks = [];
  running = false;
  onQueueFinished = null;
  lastTaskOutput = null;

  constructor() {
    this.tasks = [];
    this.running = false;
  }

  addNewTask(task) {
    this.tasks.push(task);
    if (this.running) return;

    this.run();
  }

  run() {
    this.running = true;
    this.runNextTask();
  }

  async runNextTask() {
    if (!this.running) return;
    const task = this.tasks.shift();
    if (!task) {
      this.onQueueFinished?.(this.lastTaskOutput);
      this.running = false;
      return;
    }
    try {
      this.lastTaskOutput = await task();
    } catch (error) {
      console.error(error);
    }
    await this.runNextTask();
  }
}
