import { useContext, useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { XIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import "./TagFilter.css";
import { LABELS_TO_EXCLUDE_FOR_SEARCH } from "../../../constants/labelConfig";
import { DataContext } from "../../../context/DataContext";
import { TagContext } from "../../../context/TagContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faWarning,
  faRedo,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import ProgressPie from "./PieChart";
import { Popover } from "react-tiny-popover";
import { toast } from "../Toast";
import { CgSpinnerTwo } from "react-icons/cg";
import { useUserProfile } from "../../../context/UserProfile";
import { runningTasksAtom } from "../../../atoms";
import { useAtom } from "jotai";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TagFilter({
  label,
  categoryKey,
  options = [],
  onFilterChange,
  selectedOptions,
  handleReset,
  showScreen,
  isBeingDeleted,
}) {
  const hasSelection = selectedOptions && selectedOptions.size > 0;

  const {
    deleteAllLabel,
    editTag,
    availableTags,
    currentDataGroup,
    hiddenCategories,
    failedTags,
    toggleCategoryVisibility,
  } = useContext(DataContext);
  const {
    relatedInfo,
    setRelatedInfo,
    processTag,
    processingTags,
    setProcessingTags,
  } = useContext(TagContext);
  const [tagDict, setTagDict] = useState({
    ...availableTags.llm.tagger_params.tag_dict,
  });
  const [runningTasks, setRunningTasks] = useAtom(runningTasksAtom);
  const { permissions } = useUserProfile();
  const [searchTearm, setSearchTearm] = useState("");

  useEffect(() => {
    const calculateRelatedInfo = () => {
      let info = {};
      Object.entries(tagDict).forEach(([key, value]) => {
        let counter = 0;
        let name = value.name;
        let matchingNames = [];

        Object.entries(currentDataGroup).forEach(([groupKey, groupValue]) => {
          if (!groupValue.hasOwnProperty(name)) {
            counter++;
            matchingNames.push(groupKey);
          }
        });

        info[key] = { name, counter, matchingNames };
      });

      setRelatedInfo(info);
    };

    calculateRelatedInfo();
  }, [currentDataGroup]);

  return (
    <div
      className={`flex relative h-full justify-between border-b py-4 px-4 dark:border-b-zinc-600 dark:bg-zinc-800 dark:text-white`}
    >
      <div className="h-full flex w-20 items-center justify-center">
        <ProgressPie
          percentage={
            relatedInfo[categoryKey]
              ? (1 -
                  relatedInfo[categoryKey].counter /
                    Object.keys(currentDataGroup).length) *
                100
              : 0
          }
        />
      </div>

      <div
        className={`flex w-full justify-between px-4 ${
          showScreen !== "catalog" && "menu-button-catalog"
        } ${
          isBeingDeleted
            ? "opacity-50 pointer-events-none"
            : "opacity-100 pointer-events-auto"
        }`}
      >
        <div>
          <div className="TagHeaderContainer">
            {" "}
            <span className="font-medium text-sm">{label}</span>
            {processingTags.map(({ label }) => label).includes(label) && (
              <div>
                <div className="animate-spin">
                  <CgSpinnerTwo />
                </div>
              </div>
            )}
            <button
              className="toggle-visibility-button"
              onClick={(e) => toggleCategoryVisibility(e, categoryKey)}
              title={
                hiddenCategories.includes(categoryKey)
                  ? "Unhide Category"
                  : "Hide Category"
              }
            >
              {hiddenCategories.includes(categoryKey) ? (
                <EyeOffIcon className="h-5 w-5" />
              ) : (
                <EyeIcon className="h-5 w-5" />
              )}
            </button>
          </div>

          <div>
            <p className="TagDescription h-12 text-sm">
              {tagDict[label] && tagDict[label].description}
            </p>
          </div>

          <div className="flex">
            <div>
              {!processingTags.map(({ label }) => label).includes(label) ? (
                <button
                  onClick={() => {
                    const existingIndex = runningTasks.findIndex(
                      (task) => task.categoryKey === categoryKey
                    );
                    toast.success({
                      title: "Success",
                      description: `Tag queued for ${relatedInfo[categoryKey].counter} documents`,
                    });
                    if (existingIndex === -1) {
                      const newTask = {
                        categoryKey,
                        aborted: false,
                      };
                      setRunningTasks(() => {
                        return [...runningTasks, newTask];
                      });
                    } else {
                      const updatedTask = {
                        ...runningTasks[existingIndex],
                        aborted: false,
                      };
                      setRunningTasks((prevTasks) => {
                        prevTasks[existingIndex] = updatedTask;
                        return [...prevTasks];
                      });
                    }
                    return processTag("RerunTag", categoryKey);
                  }}
                  disabled={!relatedInfo?.[categoryKey]?.counter}
                  className={`edit-button ${
                    !relatedInfo?.[categoryKey]?.counter
                      ? "opacity-50"
                      : "opacity-100"
                  } 
                      bg-primary
                  `}
                >
                  <p style={{ fontSize: "12px" }}>
                    <>
                      Tag{" "}
                      {(relatedInfo[categoryKey] &&
                        relatedInfo[categoryKey].counter) ||
                        0}{" "}
                      documents
                    </>
                  </p>
                </button>
              ) : (
                <button
                  onClick={() => {
                    const existingIndex = runningTasks.findIndex(
                      (task) => task.categoryKey === categoryKey
                    );
                    const updatedTask = {
                      ...runningTasks[existingIndex],
                      aborted: true,
                    };
                    setRunningTasks((prevTasks) => {
                      prevTasks[existingIndex] = updatedTask;
                      return [...prevTasks];
                    });
                    processingTags
                      .find(({ label: _label }) => label === _label)
                      .controller.abort();

                    setProcessingTags((prev) =>
                      prev.filter(({ label: _label }) => _label !== label)
                    );
                  }}
                  disabled={!relatedInfo?.[categoryKey]?.counter}
                  className={`edit-button ${
                    !relatedInfo?.[categoryKey]?.counter
                      ? "opacity-50"
                      : "opacity-100"
                  } bg-red-400`}
                >
                  <p style={{ fontSize: "12px" }}>Abort tagging</p>
                </button>
              )}
              {
                <span className="tooltip-text">
                  {relatedInfo[categoryKey] &&
                  relatedInfo[categoryKey].counter === 0
                    ? "All files are tagged"
                    : "Run tagging on all files untagged"}
                </span>
              }
            </div>
            {permissions.tags.canEdit && (
              <>
                <button
                  className="button-edit-button-labels bg-secondary"
                  onClick={(e) => editTag(e, categoryKey)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  className="button-delete-button-labels"
                  onClick={(e) => deleteAllLabel(e, categoryKey)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </>
            )}
            {failedTags.has(categoryKey) && (
              <button
                className="tag-failed-label"
                title={`Tag failed on ${
                  failedTags.get(categoryKey).length
                } file(s) - Re-run`}
                onClick={() => {
                  const existingIndex = runningTasks.findIndex(
                    (task) => task.categoryKey === categoryKey
                  );
                  toast.success({
                    title: `Re-run of failed tag queued: ${categoryKey}`,
                    description: "",
                  });
                  if (existingIndex === -1) {
                    const newTask = {
                      categoryKey,
                      aborted: false,
                    };
                    setRunningTasks(() => {
                      return [...runningTasks, newTask];
                    });
                  } else {
                    const updatedTask = {
                      ...runningTasks[existingIndex],
                      aborted: false,
                    };
                    setRunningTasks((prevTasks) => {
                      prevTasks[existingIndex] = updatedTask;
                      return [...prevTasks];
                    });
                  }
                  processTag("ReRunFailed", categoryKey);
                }}
              >
                <FontAwesomeIcon icon={faWarning} />
                <FontAwesomeIcon icon={faRedo} />
              </button>
            )}
          </div>
        </div>
        <Menu as="div">
          <Popover
            isOpen={true}
            positions={["bottom", "left"]} // preferred positions by priority
            padding={10}
            align="end"
            containerClassName="z-[999999999]"
            content={
              <Menu.Items className="drop-down flex flex-col">
                <input
                  value={searchTearm}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSearchTearm(e.target.value);
                  }}
                  placeholder="search"
                  className="w-full h-12 bg-slate-200 p-4 outline-none"
                />
                <div className="py-1 overflow-auto">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          "menu-item",
                          active && "active-item"
                        )}
                        onClick={() => {
                          setSearchTearm("");
                          onFilterChange(categoryKey, new Set(options));
                        }}
                      >
                        All
                      </button>
                    )}
                  </Menu.Item>
                  {options
                    .filter((option) => {
                      return (
                        option &&
                        option.toLowerCase().includes(searchTearm.toLowerCase())
                      );
                    })
                    .map((option) => (
                      <Menu.Item key={option}>
                        {({ active }) => (
                          <button
                            className={classNames(
                              "menu-item",
                              active ||
                                (selectedOptions && selectedOptions.has(option))
                                ? "active-item bg-blue-500 text-white"
                                : "text-gray-700",
                              "hover:bg-blue-500 hover:text-white cursor-pointer"
                            )}
                            onClick={() => {
                              setSearchTearm("");
                              const newSelectedOptions = new Set(
                                selectedOptions || []
                              );
                              if (newSelectedOptions.has(option)) {
                                newSelectedOptions.delete(option);
                              } else {
                                newSelectedOptions.add(option);
                              }
                              onFilterChange(categoryKey, newSelectedOptions);
                            }}
                          >
                            {option}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                </div>
              </Menu.Items>
            }
          >
            <Menu.Button className="cursor-pointer dropdown-icon-container">
              <FontAwesomeIcon icon={faFilter} className="text-gray-400" />
              {hasSelection && (
                <span className="badge-indicator">{selectedOptions.size}</span>
              )}
              <XIcon
                className={`delete-button-dropdown icon ${
                  LABELS_TO_EXCLUDE_FOR_SEARCH.includes(categoryKey)
                    ? "opacity-50"
                    : "opacity-100"
                }`}
                onClick={(e) =>
                  !LABELS_TO_EXCLUDE_FOR_SEARCH.includes(categoryKey) &&
                  handleReset(e, categoryKey)
                }
              />
            </Menu.Button>
          </Popover>
        </Menu>
      </div>
    </div>
  );
}
