import { ENDPOINTS } from "../../../api/endpoints";
import { sendRequest } from "./api";
import { Auth } from "aws-amplify";
import {
  API_USERNAME_KEYWORD,
} from "../../../constants/fixedValues";

export const addNewCatalog = async (catalog_name) => {
  const rawResponse = await sendRequest(
    {
      catalog_name: catalog_name,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["add_new_catalog"]
  );

  if (rawResponse.ok) {
    return { success: true };
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const deleteCatalog = async (catalog_name) => {
  const rawResponse = await sendRequest(
    {
      catalog_name: catalog_name,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["delete_catalog"]
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return jsonResponse;
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const updateCatalog = async (catalog_name, catalog) => {
  await sendRequest(
    {
      catalog: JSON.stringify(catalog),
      catalog_name: catalog_name,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["upload_catalog"]
  );
};

export const uploadTags = async (tags, usedCatalog) => {
  const response = await sendRequest(
    {
      tags: JSON.stringify(tags),
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
      catalog_name: usedCatalog,
    },
    ENDPOINTS["upload_tags"]
  );
};

export const uploadRules = async (rules, usedCatalog) => {
  await sendRequest(
    {
      rules: JSON.stringify(rules),
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
      catalog_name: usedCatalog,
    },
    ENDPOINTS["upload_rules"]
  );
};

export const getRules = async (usedCatalog) => {
  const rawResponse = await sendRequest(
    {
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
      catalog_name: usedCatalog,
    },
    ENDPOINTS["get_rules"]
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return jsonResponse.rules;
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const savePreferences = async (user_profile) => {
  await sendRequest(
    {
      profile: user_profile,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["save_user_profile"]
  );
};

export const getPreferences = async () => {
  const rawResponse = await sendRequest(
    {
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["load_user_profile"]
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return jsonResponse.profile;
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const getCatalogSummary = async (catalog) => {
  try {
    const payload = {
      catalog: JSON.stringify(catalog),
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    };

    const rawResponse = await sendRequest(
      payload,
      ENDPOINTS["get_catalog_summary"]
    );

    if (rawResponse.ok) {
      const jsonResponse = await rawResponse.json();
      return {
        catalog_summary: jsonResponse.catalog_summary,
        search_details: jsonResponse.search_details,
      };
    } else {
      console.error("API call failed", await rawResponse.text());
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

export async function fetchDocumentContent(
  path,
  data_stores,
  chunks = ["none"],
  pii = false,
  source = "s3"
) {
  let dataStore = data_stores[source];
  const sendObject = {
    data_store: JSON.stringify({
      path: path,
      storage: dataStore.storage,
    }),
    [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    chunk_indeces: chunks,
    pii: pii,
  };

  const rawResponse = await sendRequest(
    sendObject,
    ENDPOINTS["get_document_content"]
  );
  const response = await rawResponse.json();
  return response.content_text;
}
