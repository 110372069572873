import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./DataCatalog.css";
import LoadComponent from "../../../../utilities/LoadComponent/LoadComponent";
import SearchBar from "../../../../utilities/SearchBar/SearchBar";
import TagFilter from "../../../../utilities/TagFilter/TagFilter";
import DataGraph from "./DataCatalogComponents/DataGraph/DataGraph";
import DataList from "./DataCatalogComponents/DataList/DataList";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { Auth } from "aws-amplify";
import {
  getCatalogSummary,
  updateCatalog,
} from "../../../../utilities/functions/apiCalls";
import Standardization from "./DataCatalogComponents/Standardization/Standardization";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";
import { toast } from "./../../../../utilities/Toast";
import { faWarning, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Debouncer } from "./../../../../../utils/debounce";

export default function DataCatalog() {
  const {
    showScreen,
    setCatalogFiles,
    catalogSummary,
    setCatalogSummary,
    catalogFiles,
    setCurrentDataGroup,
    currentDataGroup,
    availableTags,
    quarantinedFiles,
    setQuarantinedFiles,
    setSearchTerm,
    searchTerm,
    searchDetails,
    isLoading,
    setHiddenCategories,
    selectedFilters,
    setSelectedFilters,
    preferences,
    setSearchDetails,
    failedTags,
    tagsToBeDeleted,
    usedCatalog,
  } = useContext(DataContext);
  const { processTag } = useContext(TagContext);

  const [answerLoading, setAnswerLoading] = useState(false);
  const [mappings, setMappings] = useState({});
  const [showStandardization, setShowStandardization] = useState(false);
  const [standardizeTag, setStandardizeTag] = useState("");
  const [standardizeScreen, setStandardizeScreen] = useState("config");
  const [standardizeSensitivity, setStandardSensitivity] = useState(0.5);
  const [standardizeMinCategories, setStandardizeMinCategories] = useState(3);
  const [standardizationLoading, setStandardizationLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState("none");
  const lastFetchedTimeRef = useRef(Date.now());

  const menuRef = useRef();
  const noOfDocumentsWithFailedTags = useMemo(
    () => new Set(Array.from(failedTags.values()).flat()).size,
    [failedTags]
  );

  const handleFilterChange = (categoryKey, selectedOptions) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [categoryKey]: selectedOptions,
    }));
  };

  const getSummary = () =>
    Debouncer.debounce(async () => {
      const newSummary = await getCatalogSummary(currentDataGroup);
      if (!newSummary) return;
      setCatalogSummary(newSummary.catalog_summary);
      setSearchDetails(newSummary.search_details);
    }, 5000);

  useEffect(() => {
    getSummary();
  }, [currentDataGroup]);

  const clearAllFilters = () => {
    setSelectedFilters({});
    setHiddenCategories([]);
    setSearchTerm("");
  };

  const handleResetFilter = (e, category) => {
    e.stopPropagation();
    const filterCopy = { ...selectedFilters };
    delete filterCopy[category];
    setSelectedFilters(filterCopy);
  };

  const standardizeValues = async (e, tag) => {
    setStandardizeScreen("config");
    e.stopPropagation();
    setStandardizeTag(tag);
    setShowStandardization(true);
  };

  const runStandardization = async () => {
    setStandardizationLoading(true);
    setStandardizeScreen("mappings");
    const rawConsolidationResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        tag: standardizeTag,
        catalog: JSON.stringify(catalogFiles),
        sensitivity: standardizeSensitivity,
        minimum_categories: parseInt(standardizeMinCategories),
      },
      ENDPOINTS["get_standardize_tag_map"]
    );

    const catalogResponse = await rawConsolidationResponse.json();
    setMappings(catalogResponse.mapping);
    setStandardizationLoading(false);
  };

  const acceptNewValues = async () => {
    const rawConsolidationResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        mapping: JSON.stringify(mappings),
        catalog: JSON.stringify(catalogFiles),
        tag: standardizeTag,
      },
      ENDPOINTS["apply_standardize_tag_map"]
    );
    const catalogResponse = await rawConsolidationResponse.json();
    setSelectedFilters({});
    setCatalogFiles(catalogResponse.new_catalog);
    setCurrentDataGroup(catalogResponse.new_catalog);
    setCatalogSummary(catalogResponse.new_catalog_summary);
    updateCatalog(usedCatalog, catalogResponse.new_catalog);
    setShowStandardization(false);
    setMappings({});
    setStandardizeScreen("config");
  };

  const quarantineFiles = async () => {
    const fileNames = Object.keys(currentDataGroup);
    const totalFiles = fileNames.length;

    const confirmQuarantine = window.confirm(
      `You are about to quarantine ${totalFiles} file(s). Are you sure you want to proceed?`
    );
    if (!confirmQuarantine) {
      return;
    }

    const newCatalogFiles = { ...catalogFiles };
    const newQuarantinedFiles = { ...quarantinedFiles };
    const constructSensitivityString = () => {
      if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
        return "No filters applied";
      }

      let sensitivityDescriptions = [];
      for (const [key, values] of Object.entries(selectedFilters)) {
        let valuesArray;

        if (values instanceof Set) {
          valuesArray = Array.from(values);
        } else if (Array.isArray(values)) {
          valuesArray = values;
        } else {
          valuesArray = [values];
        }

        if (valuesArray.length > 0) {
          const valuesString = valuesArray.join(", ");
          sensitivityDescriptions.push(`${key}: ${valuesString}`);
        }
      }

      if (sensitivityDescriptions.length === 0) {
        return "No filters applied";
      }

      return sensitivityDescriptions.join("; ");
    };

    fileNames.forEach((fileName) => {
      if (newCatalogFiles.hasOwnProperty(fileName)) {
        newQuarantinedFiles[fileName] = newCatalogFiles[fileName];
        newQuarantinedFiles[fileName].quarantine = "quarantined";
        newQuarantinedFiles[fileName].custom_sensitivity = [
          "Yes",
          constructSensitivityString(),
        ];
        delete newCatalogFiles[fileName];
      }
    });

    setCatalogFiles(newCatalogFiles);
    setQuarantinedFiles(newQuarantinedFiles);
    await updateCatalog(preferences.system.QUARANTINECATALOG, quarantinedFiles);
    await updateCatalog(usedCatalog, catalogFiles);
    clearAllFilters();
  };

  useEffect(() => {
    let data = { ...catalogFiles };

    const matchesSelectedFilters = (item, categoryKey) => {
      if (!item || !categoryKey) return false;

      if (!(categoryKey in item)) {
        return false;
      }

      const selectedOptions = selectedFilters[categoryKey];
      if (!selectedOptions || selectedOptions.size === 0) {
        return true;
      }

      const itemValue = item[categoryKey];
      if (!itemValue || !itemValue.value || !Array.isArray(itemValue.value))
        return false;

      return Array.from(selectedOptions).some((option) =>
        itemValue.value.includes(option)
      );
    };

    data = Object.keys(data).reduce((result, key) => {
      const file = data[key];
      const fileChunks = file.chunks || {};

      // Check if the file has chunks to process
      if (Object.keys(fileChunks).length > 0) {
        let matchingChunks = {};

        Object.keys(fileChunks).forEach((chunkKey) => {
          const chunk = fileChunks[chunkKey];
          if (
            Object.keys(selectedFilters).every((categoryKey) =>
              matchesSelectedFilters(chunk, categoryKey)
            )
          ) {
            matchingChunks[chunkKey] = chunk;
          }
        });

        if (Object.keys(matchingChunks).length > 0) {
          result[key] = { ...file, chunks: matchingChunks };
        }
      } else {
        result[key] = { ...file };
      }

      return result;
    }, {});

    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase().trim();
      data = Object.keys(data)
        .filter((key) => {
          const searchDetail = searchDetails[key];
          return (
            searchDetail &&
            (searchDetail.title.toLowerCase().includes(searchTermLower) ||
              searchDetail.metadata_search_string
                .toLowerCase()
                .includes(searchTermLower))
          );
        })
        .reduce((newData, key) => {
          newData[key] = data[key];
          return newData;
        }, {});
    }

    setCurrentDataGroup(data);
  }, [searchTerm, selectedFilters, catalogFiles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdownOpen("none");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  if (isLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="flex w-full h-full gap-4 overflow-hidden">
      <div className="flex flex-col rounded-md">
        <Standardization
          mappings={mappings}
          setMappings={setMappings}
          showStandardization={showStandardization}
          setShowStandardization={setShowStandardization}
          acceptNewValues={acceptNewValues}
          standardizeTag={standardizeTag}
          setStandardizeTag={setStandardizeTag}
          standardizeScreen={standardizeScreen}
          setStandardizeScreen={setStandardizeScreen}
          setStandardSensitivity={setStandardSensitivity}
          runStandardization={runStandardization}
          standardizeMinCategories={standardizeMinCategories}
          setStandardizeMinCategories={setStandardizeMinCategories}
          standardizationLoading={standardizationLoading}
        />
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        <DataList
          answerLoading={answerLoading}
          setAnswerLoading={setAnswerLoading}
        />
      </div>
      <div className="flex flex-col w-full gap-4">
        <div className="h-1/2 shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden">
          <div className="p-4 bg-slate-200 dark:bg-zinc-600 dark:text-white">
            <div className="text-sm">Tag Definitions</div>
          </div>
          <div className="flex flex-col overflow-auto">
            {catalogSummary &&
              Object.keys({
                ...availableTags.llm.tagger_params.tag_dict,
              }).map((key) => {
                if (
                  key !== "file_directory" &&
                  !Object.keys(preferences.system.SENSITIVITY_TAGS).includes(
                    key
                  ) &&
                  !preferences.system.EXCLUDE_TAGS.includes(key)
                ) {
                  return (
                    <TagFilter
                      key={key}
                      label={key}
                      categoryKey={key}
                      options={catalogSummary[key]?.availableValues || []}
                      onFilterChange={handleFilterChange}
                      selectedOptions={selectedFilters[key]}
                      handleReset={handleResetFilter}
                      showScreen={showScreen}
                      standardizeValues={standardizeValues}
                      catalogSummary={catalogSummary}
                      dropdownOpen={dropdownOpen}
                      setDropdownOpen={setDropdownOpen}
                      isBeingDeleted={tagsToBeDeleted.includes(key)}
                    ></TagFilter>
                  );
                }
                return null;
              })}
          </div>
          <div className="FilterActions bg-slate-200 dark:bg-zinc-600">
            <button
              className="bg-primary hover:bg-secondary px-4 py-2 text-white rounded-md"
              onClick={quarantineFiles}
            >
              Quarantine
            </button>
            {/* <button
              className="Button StopTaggingButton"
              onClick={() => window.location.reload(false)}
            >
              Stop Tagging
            </button> */}
            <button
              className="bg-primary hover:bg-secondary px-4 py-2 text-white rounded-md"
              onClick={clearAllFilters}
            >
              Clear Filters
            </button>
            {failedTags.size > 0 && (
              <button
                className="Button BatchActionButton"
                title={`${failedTags.size} Tag(s) failed on ${noOfDocumentsWithFailedTags} file(s) - Re-run all`}
                onClick={() => {
                  failedTags.forEach((_, failedTag) => {
                    processTag("ReRunFailed", failedTag);
                  });

                  toast.success({
                    title: `Re-run of all failed tags queued: ${failedTags.size}`,
                    description: "",
                  });
                }}
              >
                <FontAwesomeIcon icon={faWarning} />
                <FontAwesomeIcon icon={faRedo} />
                Re-run failed tags: {failedTags.size}
              </button>
            )}
          </div>
        </div>
        <DataGraph selectedFilters={selectedFilters} />
      </div>
    </div>
  );
}
