export const LABELS_TO_EXCLUDE_FOR_FRONTEND = [
  "file_directory",
  "document_length",
  "word_count",
  "relevance",
  "timeliness",
  "file_size",
  "storage_type",
  "storage_name",
  "chunks",
];

export const LABELS_TO_EXCLUDE_FOR_FRONTEND_GRAPH = [
  "file_directory",
  "document_length",
  "word_count",
  "file_size",
  "storage_type",
  "storage_name",
];

export const LABELS_TO_EXCLUDE_FOR_SEARCH = [
  "file_directory",
  "file_type",
  "file_size",
  "pii",
  "pii_entities",
  "document_length",
  "word_count",
  "keywords",
  "year",
  "month",
  "day",
  "file_size_bucket",
  "document_length_bucket",
  "word_count_bucket",
  "storage_type",
  "storage_name",
  "chunks",
  "access_group",
];

export const LABELS_FOR_FRONTEND_DETECTION = ["pii", "timeliness", "relevance"];

export const USECASETYPES = ["Chat bot", "Text Generation"];
