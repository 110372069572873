import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import "./MappingList.css";

const MappingList = ({ mappings, setMappings, ...props }) => {
  const [editingGroup, setEditingGroup] = useState(null);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const handleEditGroup = (group) => {
    const newGroupName = prompt("Edit group name:", group);
    if (newGroupName && newGroupName !== group) {
      const newMappings = {
        ...mappings,
        [newGroupName]: mappings[group],
      };
      delete newMappings[group];
      setMappings(newMappings);
      setEditingGroup(null);
    }
  };
  const handleChangeValueGroup = (item, currentGroup, newGroup) => {
    if (newGroup && newGroup !== currentGroup) {
      const newMappings = { ...mappings };
      newMappings[currentGroup] = newMappings[currentGroup].filter(
        (i) => i !== item
      );
      newMappings[newGroup] = [...newMappings[newGroup], item];
      setMappings(newMappings);
    }
  };

  return (
    <animated.div style={fadeIn}>
      {!props.standardizationLoading ? (
        <>
          <h2 className="mapping-header">
            Standardization {props.standardizeTag}
          </h2>
          <div className="description-container">
            On the left, you see the{" "}
            <span className="description-highlight text-primary">proposed categories</span>{" "}
            and on the right, the previous values which would fall under them.
            You can rename the entire group by clicking{" "}
            <span className="description-highlight text-primary">"Edit Group"</span> or
            reassign the previous values to another group.
          </div>
          <ul className="mapping-list">
            {mappings &&
              Object.entries(mappings).map(([group, items]) => (
                <li
                  key={group}
                  className={`group-mapping-list ${
                    editingGroup === group ? "editing" : ""
                  }`}
                >
                  <div className="SuggestedTag">
                    {group}
                    <p className="mapping-help-text">
                      Click on the 'Edit Group' button to modify the group name.
                      Use the dropdown next to each word to assign it to a
                      different group.
                    </p>
                    <div className="group-buttons-mapping-list">
                      <button
                        onClick={() => handleEditGroup(group)}
                        title="Click to edit or stop editing the group name"
                        className="edit-group-button bg-primary"
                      >
                        Edit Group
                      </button>
                    </div>
                  </div>
                  <ul>
                    {items &&
                      items.map((item) => (
                        <li key={item} className="value-mapping-list">
                          <div className="">
                            <p>{item}</p>
                            <select
                              value={group}
                              onChange={(e) =>
                                handleChangeValueGroup(
                                  item,
                                  group,
                                  e.target.value
                                )
                              }
                            >
                              {Object.keys(mappings).map((g) => (
                                <option key={g} value={g}>
                                  {g}
                                </option>
                              ))}
                            </select>
                          </div>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
          <div className="mapping-bottom-info">
            <p>
              Once you are satisfied with the standardization, click the
              'Submit' button below to save your changes.
            </p>
            <div className="button-container">
              <button
                className="submit-button-mapping bg-primary"
                onClick={props.acceptNewValues}
              >
                Submit
              </button>
              <button
                className="rerun-button-mapping"
                onClick={() => props.setStandardizeScreen("config")}
              >
                Rerun Standardisation
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h2 className="mapping-header">
            Loading...
          </h2>
        </>
      )}
    </animated.div>
  );
};

export default MappingList;
