import React, { useContext, useEffect, useState, useRef } from "react";
import "./ChatHistory.css";
import { COLOURS } from "../../../../../../../assets/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../../../../../../../context/DataContext";
import { ChatContext } from "../../../../../../../context/ChatContext";
import { TextGenerationUsecase } from "../DocumentList/DocumentList";

function ChatHistory() {
    const {
      isHistoryLoading,
      getUsecaseHistory,
      chats,
      currentChatId,
      setCurrentChatId,
      createChat,
      deleteChat,
      messages,
      setMessages,
    } = useContext(ChatContext);
  
    const [newChatName, setNewChatName] = useState('');
    const [visibleOptionsChatId, setVisibleOptionsChatId] = useState(null);
    const chatItemRefs = useRef({});
  
    useEffect(() => {
      getUsecaseHistory();
    }, [currentChatId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (visibleOptionsChatId !== null) {
                const currentRef = chatItemRefs.current[visibleOptionsChatId];
                if (currentRef && !currentRef.contains(event.target)) {
                    setVisibleOptionsChatId(null);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [visibleOptionsChatId]);
  
    const handleSelectChat = (chatId) => {
        if (chatId === currentChatId) {
            return;
        }
        
        if (chats && chatId && chats[chatId]) {
            const chatMessages = chats[chatId].messages.map((msg, index) => ({
                id: msg.id,
                text: msg.content,
                type: msg.role,
            }));
            setMessages(chatMessages);
        } else {
            setMessages([]);
        }
        setCurrentChatId(chatId);
    };
  
    const handleCreateChat = () => {
      if (newChatName.trim()) {
        createChat(newChatName.trim());
        setNewChatName('');
      }
    };
  
    const handleDeleteChat = (chatId) => {
      deleteChat(chatId);
    };

    const toggleOptionsVisibility = (chatId) => {
        setVisibleOptionsChatId(prevId => prevId === chatId ? null : chatId);
    };
  
    return (
      <div className="chatHistorySidebar">
        <div className="chatHistoryHeader">
            <header>
                <h1>Chat History</h1>
            </header>
        </div>
        <div className="chatHistoryContent w-80">
            {chats&&Object.entries(chats).map(([chatId, { chat_name, messages }]) => (
                <div 
                    key={chatId} 
                    ref={(el) => (chatItemRefs.current[chatId] = el)}
                    className={`chatItem ${currentChatId === chatId ? "active" : ""}`}
                    onClick={() => handleSelectChat(chatId)}
                >
                    <div style={{ flex: 1 }}>
                        {chat_name}
                    </div>
                    <button className="optionsButton" onClick={(e) => {
                        e.stopPropagation();
                        toggleOptionsVisibility(chatId);
                    }}>
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    {visibleOptionsChatId === chatId && (
                        <div className="optionsDropdown">
                            <button onClick={() => handleDeleteChat(chatId)}>Delete</button>
                        </div>
                    )}
                </div>
            ))}
            
            </div>
            <div className="createChatContainer px-4 py-4 ">
            <input 
                type="text"
                className="createChatInput"
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                placeholder="New chat name"
            />
            <button className="text-sm z-50 w-full text-white bg-primary hover:bg-secondary px-4 py-4 rounded-b shrink-0 grow-0" onClick={handleCreateChat}>Create</button>
        </div>
      </div>
    );  
  }
  
  export default ChatHistory;