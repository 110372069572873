import React, { createContext, useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";

import {
  countStatusValues,
  filterCatalogByFailedTag,
  filterDataGroupByRelatedInfo,
  mergeTagWithDefaults,
  updateParentLabelObj,
} from "../components/utilities/functions/utils";
import { DataContext, defaultCurrentTag } from "./DataContext";
import { uploadTags } from "../components/utilities/functions/apiCalls";
import { toast } from "../components/utilities/Toast";

export const TagContext = createContext();

export const TagProvider = ({ children }) => {
  const {
    setShowScreen,
    currentTag,
    setCurrentTag,
    setCurrentTotalProcessCount,
    setProcessingFile,
    availableTags,
    setAvailableTags,
    currentDataGroup,
    preferences,
    tagReRun,
    failedTags,
    usedCatalog,
  } = useContext(DataContext);

  const [isActiveAction, setIsActiveAction] = useState(false);
  const [prevCount, setPrevCount] = useState(0);
  const [processingTags, setProcessingTags] = useState([]);
  const defaultTagTypes = {
    sensitivity: "Sensitivity",
    classification: "Classification",
  };

  const [relatedInfo, setRelatedInfo] = useState({});
  const [tagDict, setTagDict] = useState({
    ...availableTags.sensitivity.tagger_params.tag_dict,
    ...availableTags.llm.tagger_params.tag_dict,
  });

  const processTag = async (tagMethod, tagKey = null) => {
    const controller = new AbortController();
    setProcessingTags((prev) => [...prev, { label: tagKey, controller }]);
    setIsActiveAction(true);
    setPrevCount(countStatusValues(currentDataGroup, "Processing").Processing);

    let currentDataGroupSnapshot = { ...currentDataGroup };
    const creds = (await Auth.currentAuthenticatedUser()).username;
    let newTag = {};

    if (tagMethod === "addTag") {
      if (!currentTag.name.trim() || !currentTag.description.trim()) {
        toast.error({
          title: "Error",
          description:
            "Please fill out the name, description, and type of tag before proceeding.",
        });
        return false;
      }
      newTag = { ...currentTag };
      setCurrentTag(preferences.webapp_profile.DEFAULT_TAG);
      const currentTags = availableTags;
      const updatedTags = updateParentLabelObj(currentTags, newTag);

      uploadTags(updatedTags, usedCatalog);
      setAvailableTags(updatedTags);
    } else if (tagMethod === "ReRunFailed") {
      currentDataGroupSnapshot = filterCatalogByFailedTag(
        currentDataGroupSnapshot,
        failedTags.get(tagKey) || []
      );

      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];

      tagReRun(tagKey);
    } else if (tagMethod === "RunAll") {
      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];

      tagReRun(tagKey);
    } else {
      currentDataGroupSnapshot = filterDataGroupByRelatedInfo(
        currentDataGroupSnapshot,
        relatedInfo[tagKey].matchingNames
      );

      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];
    }

    setCurrentTotalProcessCount(Object.keys(currentDataGroupSnapshot).length);
    try {
      setShowScreen("catalog");
      setCurrentTotalProcessCount(Object.keys(currentDataGroupSnapshot).length);

      const promises = [];

      for (const [id, catalogItem] of Object.entries(
        currentDataGroupSnapshot
      )) {
        setProcessingFile(id);

        const sendObject = {
          data_store: JSON.stringify({
            ...preferences.webapp_profile.DATA_STORES[catalogItem.storage_type],
            path: `${catalogItem.file_directory}/${id}`,
          }),
          tagger_list: JSON.stringify({
            llm: {
              tagger_params: {
                model: {
                  provider: preferences.webapp_profile.PROVIDER_USED,
                  version: preferences.webapp_profile.MODEL_USED,
                },
                iters: 1,
                tag_dict: { [newTag.name]: newTag },
              },
            },
          }),
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          file_catalog_entry: JSON.stringify({ [id]: catalogItem }),
          pii: newTag.pii || false,
          catalog_name: usedCatalog,
          quarantine_name: preferences.system.QUARANTINECATALOG,
          check_sensitivity: false,
        };

        promises.push(
          sendRequest(
            sendObject,
            ENDPOINTS["create_catalog"],
            undefined,
            undefined,
            controller.signal
          )
        );

        // TODO: Fix progress bar update / remove
        // TODO: Move catalog summary and search detail update to function that checks "Processed" for catalog and then calls APIs
        // setCurrentProcessCount((prevState) => prevState + 1);
        // setCatalogSummary(consolidationResponse.new_catalog_summary);
        // setSearchDetails(consolidationResponse.search_details);
      }
      await Promise.allSettled(promises);
    } catch (error) {
      console.log("Error adding tag:", error);
      setProcessingFile(null);
    } finally {
      setProcessingTags((prev) => prev.filter(({ label }) => label !== tagKey));
      setProcessingFile(null);
      setIsActiveAction(false);
    }
  };

  const saveTag = async (currentTag) => {
    const newTag = { ...currentTag };

    const hasValidName =
      newTag.name && typeof newTag.name === "string" && newTag.name.trim();
    const hasValidDescription =
      newTag.description &&
      typeof newTag.description === "string" &&
      newTag.description.trim();
    const hasValidTagType =
      newTag.tagType &&
      typeof newTag.tagType === "string" &&
      newTag.tagType.trim();

    if (!hasValidName || !hasValidDescription || !hasValidTagType) {
      return false;
    }

    toast.info({
      title: "Info",
      description: "Preparing tag to be saved.",
    });

    const validExamples = (newTag.examples || []).filter(
      (example) =>
        example.evidence &&
        example.evidence.trim() !== "" &&
        example.value.trim() !== ""
    );

    newTag.examples = validExamples;

    const mergedTag = mergeTagWithDefaults(newTag, defaultCurrentTag);
    const currentTags = availableTags;
    const updatedTags = updateParentLabelObj(currentTags, mergedTag);
    setAvailableTags(updatedTags);
    await uploadTags(updatedTags, usedCatalog);

    toast.success({
      title: "Success",
      description: "Tag saved successfully!",
    });
  };

  return (
    <TagContext.Provider
      value={{
        // Getters
        relatedInfo,
        tagDict,
        isActiveAction,
        prevCount,
        processingTags,
        defaultTagTypes,
        // Setters
        setIsActiveAction,
        setRelatedInfo,
        setPrevCount,
        setTagDict,
        setProcessingTags,
        // Functions
        processTag,
        saveTag,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};
