import "./Preferences.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { COLOURS } from "../../../../../assets/colours";
import {
  savePreferences,
  addNewCatalog,
  deleteCatalog,
} from "../../../../utilities/functions/apiCalls";
import { DataContext } from "../../../../../context/DataContext";
import { useUserProfile } from "../../../../../context/UserProfile";

export default function UserPreferences() {
  const {
    preferences,
    setPreferences,
    availableTags,
    catalogNames,
    setCatalogNames,
  } = useContext(DataContext);
  const [currentFormValues, setCurrentFormValues] = useState(preferences);
  const [isSaving, setIsSaving] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [catalogList, setCatalogList] = useState(catalogNames);
  const [newCatalogName, setNewCatalogName] = useState("");

  const RISK_LEVEL_OPTIONS = ["High", "Medium", "Low"];
  const userProfile = useUserProfile();

  useEffect(() => {
    setPreferences(userProfile);
    setCurrentFormValues(userProfile);
    setSelectedTags(
      safeParseJsonStringOrString(userProfile.hidden_tags.HIDDEN_TAGS) || [],
    );
  }, [setPreferences]);

  const handleChange = (path, newValue) => {
    setCurrentFormValues((prevValues) => {
      const newValues = { ...prevValues };
      const pathKeys = path.split(".");
      let lastObj = newValues;
      for (let i = 0; i < pathKeys.length - 1; i++) {
        const key = pathKeys[i];
        if (!lastObj[key]) lastObj[key] = {};
        lastObj = lastObj[key];
      }
      lastObj[pathKeys[pathKeys.length - 1]] = newValue;
      return newValues;
    });
  };

  const handleSelectChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const addTag = (currentPath) => {
    if (selectedTag && !selectedTags.includes(selectedTag)) {
      const updatedTags = [...selectedTags, selectedTag];
      handleChange(currentPath, updatedTags);
      setSelectedTag("");
      setSelectedTags(updatedTags);
    }
  };

  const removeTag = (tagToRemove, currentPath) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    handleChange(currentPath, updatedTags);
    setSelectedTags(updatedTags);
  };

  const renderTagDropdown = (currentPath) => {
    const tags = Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...availableTags.sensitivity.tagger_params.tag_dict,
      ...Object.fromEntries(
        preferences.system.EXCLUDE_TAGS.map((tag) => [tag, true]),
      ),
    });

    return (
      <div>
        <select
          className="tag-dropdown"
          value={selectedTag}
          onChange={handleSelectChange}
        >
          {tags.map(
            (tag) =>
              !selectedTags.includes(tag) && (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ),
          )}
        </select>
        <button className="add-tag-btn" onClick={() => addTag(currentPath)}>
          Add Tag
        </button>
      </div>
    );
  };

  const renderSelectedTags = (currentPath) => {
    return (
      <ul className="tag-list">
        {selectedTags.map((tag) => (
          <li key={tag} className="tag-item">
            {tag}
            <button
              className="tag-remove-btn"
              onClick={() => removeTag(tag, currentPath)}
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const safeParseJsonStringOrString = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };

  const safeParseJsonStringOrNull = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  };

  const renderInputs = (data, path = "") => {
    return Object.entries(data).map(([key, value]) => {
      const currentPath = path ? `${path}.${key}` : key;
      const inputValue = getNestedValue(
        currentFormValues[activeTab] || {},
        currentPath.split("."),
      );

      const json_parsed = safeParseJsonStringOrNull(value);

      if (key === "HIDDEN_TAGS") {
        return (
          <div key={currentPath}>
            {renderSelectedTags(`${activeTab}.${currentPath}`)}
            {renderTagDropdown(`${activeTab}.${currentPath}`)}
          </div>
        );
      } else if (key === "OPENAI_ENDPOINTS") {
        return (
          <div key={currentPath}>
            <label>{currentPath}:</label>
            <DynamicTable
              initialData={value}
              onDataChange={(newData) =>
                handleChange(`${activeTab}.${currentPath}`, newData)
              }
            />
          </div>
        );
      } else if (key === "ACCESS_GROUP_RISK_LABEL") {
        return (
          <label key={currentPath} className="preferences-dropdown-label">
            {currentPath}:
            <select
              id={currentPath}
              value={inputValue || ""}
              onChange={(e) =>
                handleChange(`${activeTab}.${currentPath}`, e.target.value)
              }
            >
              {RISK_LEVEL_OPTIONS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
        );
      } else if (key === "USE_VECTOR_STORAGE_CONFIG") {
        return (
          <label key={currentPath} className="preferences-checkbox-label">
            {currentPath}:
            <input
              id={currentPath}
              type="checkbox"
              checked={inputValue}
              onChange={(e) =>
                handleChange(`${activeTab}.${currentPath}`, e.target.checked)
              }
            />
          </label>
        );
      } else if (
        typeof inputValue !== "number" &&
        (json_parsed || typeof inputValue === "object")
      ) {
        return (
          <div key={currentPath} className="preferences-json-container">
            <label htmlFor={currentPath}>{currentPath}:</label>
            <textarea
              id={currentPath}
              className="preferences-json-textarea"
              value={
                typeof inputValue === "string"
                  ? inputValue
                  : JSON.stringify(inputValue, null, 2)
              }
              onChange={(e) =>
                handleChange(`${activeTab}.${currentPath}`, e.target.value)
              }
            />
          </div>
        );
      } else {
        return (
          <label key={currentPath} className="preferences-input-label">
            {currentPath}:
            <input
              id={currentPath}
              type={typeof inputValue === "number" ? "number" : "text"}
              value={inputValue ?? ""}
              onChange={(e) =>
                handleChange(
                  `${activeTab}.${currentPath}`,
                  e.target.type === "number" ? +e.target.value : e.target.value,
                )
              }
            />
          </label>
        );
      }
    });
  };

  const renderCatalogsContent = () => {
    return (
      <div className="preferences-category">
        <ul className="catalog-list">
          {catalogList.map((name, index) => (
            <li key={index} className="catalog-item">
              {name}
              <button
                className="catalog-delete-btn"
                onClick={() => deleteFromCatalog(name)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
        <div className="catalog-input-container">
          <input
            type="text"
            className="catalog-input"
            placeholder="Add new catalog"
            value={newCatalogName}
            onChange={(e) => setNewCatalogName(e.target.value)}
          />
          <button className="catalog-add-btn" onClick={addCatalog}>
            Add Catalog
          </button>
        </div>
      </div>
    );
  };

  const addCatalog = () => {
    if (newCatalogName && !catalogList.includes(newCatalogName)) {
      addNewCatalog(newCatalogName);

      const updatedCatalogs = [...catalogList, newCatalogName];
      setCatalogList(updatedCatalogs);
      setNewCatalogName("");
      setCatalogNames(updatedCatalogs);
    }
  };

  const deleteFromCatalog = (name) => {
    if (catalogList.length === 1) {
      alert("You cannot delete the last catalog!");
      return;
    }
    const isConfirmed = window.confirm(
      `Deleting '${name}' will remove access to files in this catalog. Are you sure you want to proceed?`,
    );
    if (isConfirmed) {
      deleteCatalog(name);
      const updatedCatalogs = catalogList.filter((catalog) => catalog !== name);
      setCatalogList(updatedCatalogs);
      setCatalogNames(updatedCatalogs);
    }
  };

  const getNestedValue = (obj, pathArray) => {
    return pathArray.reduce((acc, key) => {
      return acc ? acc[key] : null;
    }, obj);
  };

  const handleSubmit = () => {
    setIsSaving(true);

    const updatedPreferences = JSON.parse(JSON.stringify(currentFormValues));

    savePreferences(updatedPreferences)
      .then(() => {
        alert("Preferences saved successfully");
        setPreferences(updatedPreferences);
      })
      .catch((error) => {
        console.error("Failed to save preferences:", error);
        alert("Failed to save preferences.");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (!preferences) {
    return <div>Loading preferences...</div>;
  }

  return (
    <div className="w-full max-w-6xl bg-white p-4 rounded-md">
      <div className="preferences-tabs">
        <button
          className={`preferences-tab hover:bg-primary ${
            activeTab === "profile" ? "active" : ""
          }`}
          onClick={() => setActiveTab("profile")}
        >
          Profile Settings
        </button>
        <button
          className={`preferences-tab hover:bg-primary ${
            activeTab === "hidden_tags" ? "active" : ""
          }`}
          onClick={() => setActiveTab("hidden_tags")}
        >
          Hidden Tags
        </button>
        <button
          className={`preferences-tab hover:bg-primary ${
            activeTab === "webapp_profile" ? "active" : ""
          }`}
          onClick={() => setActiveTab("webapp_profile")}
        >
          Webapp Profile
        </button>
        <button
          className={`preferences-tab hover:bg-primary ${
            activeTab === "catalogs" ? "active" : ""
          }`}
          onClick={() => setActiveTab("catalogs")}
        >
          Catalogs
        </button>
      </div>
      <div className="preferences-content">
        {activeTab === "profile" && (
          <div className="preferences-category">
            {renderInputs(preferences.profile)}
          </div>
        )}
        {activeTab === "hidden_tags" && (
          <div className="preferences-category">
            {renderInputs(preferences.hidden_tags)}
          </div>
        )}
        {activeTab === "webapp_profile" && (
          <div className="preferences-category">
            {renderInputs(preferences.webapp_profile)}
          </div>
        )}
        {activeTab === "catalogs" && renderCatalogsContent()}
      </div>
      <button
        className="preferences-button bg-primary"
        onClick={handleSubmit}
        disabled={isSaving}
      >
        {isSaving ? "Saving..." : "Save Preferences"}
      </button>
    </div>
  );
}

function DynamicTable({ initialData, onDataChange }) {
  const staticHeaders = [
    "api_type",
    "base_url",
    "api_key",
    "max_tpm",
    "model_used",
    "embed_model_used",
    "enabled",
  ];

  // Kept the Azure options even though we don't use them anymore.
  const apiTypeOptions = {
    openai: {
      model_used: [
        "gpt-4-turbo-preview",
        "gpt-4-0125-preview",
        "gpt-4-1106-preview",
        "gpt-4",
        "gpt-4-0613",
        "gpt-4-32k",
        "gpt-4-32k-0613",
        "gpt-3.5-turbo-0125",
        "gpt-3.5-turbo",
        "gpt-3.5-turbo-1106",
        "gpt-3.5-turbo-instruct",
      ],
      embed_model_used: [
        "text-embedding-3-large",
        "text-embedding-3-small",
        "text-embedding-ada-002",
      ],
    },
    azure: {
      model_used: [
        "gpt-4",
        "gpt-4-32k",
        "gpt-35-turbo",
        "gpt-35-turbo-16k",
        "gpt-35-turbo-instruct",
      ],
      embed_model_used: ["text-embedding-ada-002"],
    },
  };

  const alignDataWithHeaders = (data) => {
    return data.map((item) => {
      const alignedItem = {};
      staticHeaders.forEach((header) => {
        alignedItem[header] =
          item[header] ?? (header === "enabled" ? true : "");
      });
      return alignedItem;
    });
  };

  const [tableData, setTableData] = useState(() =>
    alignDataWithHeaders(initialData),
  );

  const renderCellInput = (rowIndex, header, value) => {
    const apiType = tableData[rowIndex].api_type;

    if (header === "api_type") {
      return (
        <select
          value={value}
          onChange={(e) => handleRowChange(rowIndex, header, e.target.value)}
        >
          <option value="openai">OpenAI</option>
          <option value="azure">Azure</option>
        </select>
      );
    } else if (header === "model_used" || header === "embed_model_used") {
      if (apiType === "openai") {
        const options = apiTypeOptions[apiType]
          ? apiTypeOptions[apiType][header]
          : [];
        return (
          <select
            value={value}
            onChange={(e) => handleRowChange(rowIndex, header, e.target.value)}
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      } else if (apiType === "azure") {
        return (
          <input
            type="text"
            value={value}
            onChange={(e) => handleRowChange(rowIndex, header, e.target.value)}
          />
        );
      }
    } else if (header === "enabled") {
      return (
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => handleRowChange(rowIndex, header, e.target.checked)}
        />
      );
    } else {
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => handleRowChange(rowIndex, header, e.target.value)}
        />
      );
    }
  };

  const handleRowChange = (index, key, value) => {
    const newData = [...tableData];
    newData[index][key] = key === "enabled" ? value === "true" : value;
    setTableData(newData);
    onDataChange(newData);
  };

  const addRow = () => {
    const newRow = staticHeaders.reduce(
      (acc, header) => ({
        ...acc,
        [header]:
          header === "enabled" ? true : header === "api_type" ? "openai" : "",
      }),
      {},
    );
    const updatedTableData = [...tableData, newRow];
    setTableData(updatedTableData);
    onDataChange(updatedTableData);
  };

  const deleteRow = (index) => {
    const newData = tableData.filter((_, rowIndex) => rowIndex !== index);
    setTableData(newData);
    onDataChange(newData);
  };

  return (
    <div>
      <table className="preferences-table">
        <thead>
          <tr>
            {staticHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              {staticHeaders.map((header) => (
                <td key={header}>
                  {renderCellInput(index, header, row[header])}
                </td>
              ))}
              <td>
                <button className="delete-btn" onClick={() => deleteRow(index)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <button className="preferences-table-add" onClick={addRow}>
          Add Row
        </button>
      </table>
    </div>
  );
}
