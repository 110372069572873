import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import SimpleDataList from "../SimplifiedDataList/SimplifiedDataList";
import FilterComponent from "../FilterComponent/FilterComponent";
import { Auth } from "aws-amplify";
import "./FilterTableList.css";
import { getCatalogSummary } from "../functions/apiCalls";
import { DataContext } from "../../../context/DataContext";
import { searchInText } from "../functions/utils";

export default function FilterTableDataList(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFiltersSelected, setSelectedFiltersSelected] = useState({});
  const [filters, setFilters] = useState(props.catalogSummary);
  const [filtersSelected, setFiltersSelected] = useState(props.catalogSummary);
  const [availableData, setAvailableData] = useState({});
  const [filteredData, setFilteredData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchAvailable, setSearchAvailable] = useState("");
  const [searchSelected, setSearchSelected] = useState("");

  const { catalogFiles } = useContext(DataContext);

  const handleResetFilter = (e, category) => {
    e.stopPropagation();
    const filterCopy = { ...selectedFilters };
    delete filterCopy[category];
    setSelectedFilters(filterCopy);
  };
  const handleResetFilterSelected = (e, category) => {
    e.stopPropagation();
    const filterCopy = { ...selectedFiltersSelected };
    delete filterCopy[category];
    setSelectedFiltersSelected(filterCopy);
  };

  const handleAddData = async () => {
    setSearchAvailable("");
    setSearchSelected("");
    setIsLoading(true);

    const newAddedData = { ...props.addedData, ...filteredDataReduced };
    props.setAddedData((prev) => ({ ...filteredDataReduced, ...prev }));

    const newAvailableData = Object.keys(availableData)
      .filter((key) => !Object.keys(newAddedData).includes(key))
      .reduce((acc, key) => {
        acc[key] = availableData[key];
        return acc;
      }, {});
    setAvailableData(newAvailableData);
    setSelectedFilters({});
    try {
      const username = (await Auth.currentAuthenticatedUser()).username;
      const newCatalogSummary =
        await getCatalogSummary(newAvailableData).catalog_summary;

      if (newCatalogSummary) {
        setFilters(newCatalogSummary);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      props.currentUseCase.usecase_catalog &&
      Object.keys(props.currentUseCase.usecase_catalog).length !== 0
    ) {
      const newAddedData = props.currentUseCase.usecase_catalog;

      const newAvailableData = Object.keys(catalogFiles)
        .filter((key) => !Object.keys(newAddedData).includes(key))
        .reduce((acc, key) => {
          acc[key] = props.currentDataGroup[key];
          return acc;
        }, {});

      setAvailableData(newAvailableData);
    }
  }, [isModalOpen]);

  const handleAddSingleData = async (key) => {
    setSearchAvailable("");
    setSearchSelected("");
    setIsLoading(true);

    const newAddedData = {
      ...props.addedData,
      ...{ [key]: availableData[key] },
    };
    props.setAddedData(newAddedData);

    const newAvailableData = Object.keys(availableData)
      .filter((key) => !Object.keys(newAddedData).includes(key))
      .reduce((acc, key) => {
        acc[key] = availableData[key];
        return acc;
      }, {});
    setAvailableData(newAvailableData);

    try {
      const username = (await Auth.currentAuthenticatedUser()).username;
      const newCatalogSummary =
        await getCatalogSummary(newAddedData).catalog_summary;

      if (newCatalogSummary) {
        setFilters(newCatalogSummary);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (key) => {
    setSearchAvailable("");
    setSearchSelected("");
    setAvailableData((prev) => ({ ...prev, [key]: props.addedData[key] }));

    props.setAddedData((prev) => {
      const newData = { ...prev };
      delete newData[key];
      return newData;
    });
    try {
      const newCatalogSummary =
        await getCatalogSummary(availableData).catalog_summary;

      if (newCatalogSummary) {
        setFilters(newCatalogSummary);
        setSelectedFilters({});
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    setSearchAvailable("");
    setSearchSelected("");
    setAvailableData((prev) => ({ ...prev, ...addedDataReduced }));

    for (const key in addedDataReduced) {
      delete props.addedData[key];
    }

    props.setAddedData({ ...props.addedData });
    try {
      const newCatalogSummary =
        await getCatalogSummary(availableData).catalog_summary;

      if (newCatalogSummary) {
        setFilters(newCatalogSummary);
        setSelectedFilters({});
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterData = useCallback((initialData, filters) => {
    let data = { ...initialData };
    Object.keys(filters).forEach((filterKey) => {
      const selectedOptions = filters[filterKey];

      if (selectedOptions && selectedOptions.size > 0) {
        data = Object.keys(data)
          .filter((key) => {
            const itemValue = data[key][filterKey];

            if (Array.isArray(itemValue)) {
              return itemValue.some((value) => selectedOptions.has(value));
            }

            return selectedOptions.has(itemValue);
          })
          .reduce((result, key) => {
            result[key] = data[key];
            return result;
          }, {});
      }
    });

    return data;
  }, []);

  useEffect(() => {
    setFilteredData(filterData(availableData, selectedFilters));
  }, [filters, availableData, filterData, selectedFilters]);

  useEffect(() => {
    const preFilteredData = Object.keys(props.currentDataGroup).reduce(
      (acc, key) => {
        acc[key] = props.currentDataGroup[key];
        return acc;
      },
      {}
    );

    setAvailableData(preFilteredData);
  }, [props.currentDataGroup, props.currentUseCase.access_group]);

  const handleFilterChange = (filterKey, filterValue) => {
    setSelectedFilters((prev) => ({ ...prev, [filterKey]: filterValue }));
  };

  const handleFilterChangeSelected = (filterKey, filterValue) => {
    setSelectedFiltersSelected((prev) => ({
      ...prev,
      [filterKey]: filterValue,
    }));
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
  };
  const clearAllFiltersSelected = () => {
    setSelectedFiltersSelected({});
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const filteredDataReduced = useMemo(() => {
    return Object.entries(filteredData || {}).reduce((obj, [key, val]) => {
      if (!searchInText(key, searchAvailable)) return obj;
      if (!props.addedData[key]) obj[key] = val;
      return obj;
    }, {});
  }, [filteredData, props.addedData, searchAvailable]);

  const addedDataReduced = useMemo(() => {
    return filterData(
      Object.entries(props.addedData).reduce((obj, [key, val]) => {
        if (!searchInText(key, searchSelected)) return obj;
        obj[key] = val;
        return obj;
      }, {}),
      selectedFiltersSelected
    );
  }, [filterData, props.addedData, searchSelected, selectedFiltersSelected]);

  return (
    <div className="FilterTableDataList overflow-hidden relative">
      <div className="grid grid-cols-2 gap-4 overflow-hidden">
        <div className="shadow-md">
          <div className="p-4 bg-slate-200 rounded-t-md text-lg text-secondary font-medium">
            Available data ({Object.keys(filteredDataReduced).length} datasets)
          </div>
          <FilterComponent
            handleFilterChange={handleFilterChange}
            labels={props.labels}
            catalogSummary={filters}
            clearAllFilters={clearAllFilters}
            selectedFilters={selectedFilters}
            showScreen={props.showScreen}
            handleResetFilter={handleResetFilter}
          />
          <div>
            <div className="flex px-4 pt-4 bg-slate-200">
              <div className="flex w-full rounded-t-md overflow-hidden border-b-4 border-slate-100">
                <input
                  className="h-12 appearance-none border w-full py-2 px-3 text-gray-700"
                  type="text"
                  placeholder="Search"
                  value={searchAvailable}
                  onChange={(e) => setSearchAvailable(e.target.value)}
                />
                <button
                  disabled={!Object.keys(filteredDataReduced).length}
                  className={`bg-primary p-2 w-40 text-white ${
                    Object.keys(filteredDataReduced).length
                      ? "opacity-100"
                      : "opacity-60"
                  }`}
                  onClick={handleAddData}
                >
                  Add all
                </button>
              </div>
            </div>
            <SimpleDataList
              currentDataGroup={filteredDataReduced}
              setModalOpen={handleModalToggle}
              isModalOpen={isModalOpen}
              catalogSummary={props.catalogSummary}
              handleAddSingleData={handleAddSingleData}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="LoaderContainer">
            <p>Loading...</p>
          </div>
        ) : (
          <div className="shadow-md">
            <div className="p-4 bg-slate-200 rounded-t-md text-lg text-secondary font-medium">
              Data selected ({Object.keys(addedDataReduced).length} datasets)
            </div>
            <FilterComponent
              handleFilterChange={handleFilterChangeSelected}
              labels={props.labels}
              catalogSummary={filtersSelected}
              clearAllFilters={clearAllFiltersSelected}
              selectedFilters={selectedFiltersSelected}
              showScreen={props.showScreen}
              handleResetFilter={handleResetFilterSelected}
            />
            <div className="flex px-4 pt-4 bg-slate-200">
              <div className="flex w-full rounded-t-md overflow-hidden border-b-4 border-slate-100">
                <input
                  className="h-12 appearance-none border w-full py-2 px-3 text-gray-700"
                  type="text"
                  placeholder="Search"
                  value={searchSelected}
                  onChange={(e) => setSearchSelected(e.target.value)}
                />
                <button
                  disabled={!Object.keys(addedDataReduced).length}
                  className={`bg-primary p-2 w-40 text-white ${
                    Object.keys(addedDataReduced).length
                      ? "opacity-100"
                      : "opacity-60"
                  }`}
                  onClick={handleDeleteAll}
                >
                  Delete all
                </button>
              </div>
            </div>

            <SimpleDataList
              currentDataGroup={addedDataReduced}
              setModalOpen={handleModalToggle}
              isModalOpen={isModalOpen}
              labels={props.labels}
              handleDelete={handleDelete}
            />
          </div>
        )}
      </div>
      <div className="absolute right-0 bottom-0 w-full flex justify-end gap-4">
        <button
          className="px-4 py-2 bg-primary text-white rounded-md shadow-md"
          onClick={props.confirmUsecaseData}
        >
          Finish
        </button>
      </div>
    </div>
  );
}
