import React, { useContext, useEffect, useState } from "react";
import "./Export.css";
import { DataContext } from "../../../../../context/DataContext";
import { UsecaseContext } from "../../../../../context/UsecaseContext";
import { sendRequest } from "../../../functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import { Auth } from "aws-amplify";

export default function Export() {
  const [selectedOptions, setSelectedOptions] = useState({
    catalog: false,
    quarantine: false,
    usecase: false,
  });
  const [outputFormat, setOutputFormat] = useState("");

  const { catalogFiles, quarantinedFiles } = useContext(DataContext);
  const { useCases } = useContext(UsecaseContext);

  const possibleDownloads = {
    catalog: catalogFiles,
    quarantine: quarantinedFiles,
    usecases: useCases,
  };

  const handleOptionChange = (option) => {
    setSelectedOptions((prev) => ({ ...prev, [option]: !prev[option] }));
  };

  const handleFormatChange = (event) => {
    setOutputFormat(event.target.value);
  };

  const handleDownload = async (option) => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      file: JSON.stringify(possibleDownloads[option]),
      output_format: outputFormat,
      file_name: option,
      [API_USERNAME_KEYWORD]: creds,
    };

    const response = await sendRequest(sendDetails, ENDPOINTS["export"]);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${option}.${outputFormat}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleConfirm = async () => {
    if (!outputFormat || !Object.values(selectedOptions).includes(true)) {
      window.confirm(
        "Please select an output format and at least one export option."
      );
    }

    for (const option in selectedOptions) {
      if (selectedOptions[option]) {
        await handleDownload(option);
      }
    }
  };

  return (
    <div className="export-options-container">
      <h3>Select Export Options</h3>
      <div className="export-options-select-container">
        <div>
          <label>
            <input
              type="checkbox"
              checked={selectedOptions.catalog}
              onChange={() => handleOptionChange("catalog")}
            />
            Catalog
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={selectedOptions.quarantine}
              onChange={() => handleOptionChange("quarantine")}
            />
            Quarantine
          </label>
        </div>
      </div>

      <h3>Select Output Format</h3>
      <select value={outputFormat} onChange={handleFormatChange}>
        <option value="">Select Format</option>
        <option value="csv">CSV</option>
        <option value="json">JSON</option>
      </select>

      <button onClick={handleConfirm} className="bg-primary">
        Export
      </button>
    </div>
  );
}
