import "./ChatInterface.css";
import DocumentList from "./ChatInterfaceComponents/DocumentList/DocumentList";
import ChatBox from "./ChatInterfaceComponents/ChatBox/ChatBox";
import ChatHistory from "./ChatInterfaceComponents/ChatHistory/ChatHistory";
import { useContext, useState } from "react";
import { DataContext } from "../../../../../context/DataContext";

function ChatInterface() {

  const {
    usecaseSelected
  } = useContext(DataContext)

  const [documentsSelected, setDocumentsSelected] = useState(Object.keys(usecaseSelected.usecase_catalog))

  return (
    <div className="flex gap-4 w-full h-full">
      <DocumentList 
          documentsSelected ={documentsSelected} 
          setDocumentsSelected ={setDocumentsSelected} 
        />
      <ChatBox documentsSelected ={documentsSelected}/>
      <ChatHistory />
    </div>
  );
}

export default ChatInterface;
