import { useState, useEffect } from "react";
import Modal from "../../../../../../../../utilities/Modal/Modal";
import "./EvidenceTable.css";

export const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V21H19V18H5Z"
      fill="currentColor"
    />
  </svg>
);

export default function EvidenceTable(props) {
  const [tagFilter, setTagFilter] = useState("");
  const [chunkFilter, setChunkFilter] = useState("");
  const [evidenceFilter, setEvidenceFilter] = useState("");
  const [reasonFilter, setReasonFilter] = useState("");
  const [valuesFilter, setValuesFilter] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const newFilteredData = props.evidenceData?.flatMap(
      ({ tag, evidences }) => {
        const sortedEvidences = evidences.sort((a, b) => {
          const indexA = Object.keys(props.sortedChunkMappings).indexOf(
            a.chunk
          );
          const indexB = Object.keys(props.sortedChunkMappings).indexOf(
            b.chunk
          );
          return indexA - indexB;
        });

        return sortedEvidences
          .filter((evidence) => {
            const tagMatch = tag
              .toLowerCase()
              .includes(tagFilter.toLowerCase());
            const chunkMatch = (
              props.sortedChunkMappings[evidence.chunk] || evidence.chunk
            )
              .toLowerCase()
              .includes(chunkFilter.toLowerCase());
            const evidenceMatch = evidence.evidence
              .toLowerCase()
              .includes(evidenceFilter.toLowerCase());
            const reasonMatch = evidence.reason
              .toLowerCase()
              .includes(reasonFilter.toLowerCase());
            const valuesMatch = evidence.values.some((value) =>
              value.toLowerCase().includes(valuesFilter.toLowerCase())
            );

            return (
              tagMatch &&
              chunkMatch &&
              evidenceMatch &&
              reasonMatch &&
              valuesMatch
            );
          })
          .map((evidence) => ({
            tag,
            evidence: {
              ...evidence,
              originalChunk: evidence.chunk,
              chunk:
                props.sortedChunkMappings[evidence.chunk] || evidence.chunk,
            },
          }));
      }
    );

    setFilteredData(newFilteredData);
  }, [
    chunkFilter,
    evidenceFilter,
    props.evidenceData,
    props.sortedChunkMappings,
    reasonFilter,
    tagFilter,
    valuesFilter,
  ]);

  const handleDownload = () => {
    const csvRows = [
      ["Tag", "Chunk", "Evidence", "Reason", "Values"].join(","),
      ...props.evidenceData.flatMap(({ tag, evidences }) =>
        evidences.map((evidence) =>
          [
            tag,
            evidence.chunk,
            `"${evidence.evidence}"`,
            `"${evidence.reason}"`,
            evidence.values.join("; "),
          ].join(",")
        )
      ),
    ];
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "evidence-data.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  return (
    <Modal
      isOpen={props.isEvidenceModalOpen}
      onClose={() => {
        props.onClose();
        props.setIsEvidenceModalOpen(false);
        props.setShowAllEvidence(false);
      }}
      title="Evidence List"
    >
      <div className="modal-content">
        <div className="modal-header">
          <div className="text-base">{props.currentItemKey}</div>
          <button onClick={handleDownload} className="download-button">
            <DownloadIcon />
          </button>
        </div>
        <table className="evidence-table">
          <thead>
            <tr>
              <th>
                Tag
                <input
                  type="text"
                  onChange={(e) => setTagFilter(e.target.value)}
                  placeholder="Filter by Tag"
                />
              </th>
              <th>
                Chunk
                <input
                  type="text"
                  onChange={(e) => setChunkFilter(e.target.value)}
                  placeholder="Filter by Chunk"
                />
              </th>
              <th>
                Values
                <input
                  type="text"
                  onChange={(e) => setValuesFilter(e.target.value)}
                  placeholder="Filter by Value"
                />
              </th>
              <th>
                Evidence
                <input
                  type="text"
                  onChange={(e) => setEvidenceFilter(e.target.value)}
                  placeholder="Filter by Evidence"
                />
              </th>
              <th>
                Reason
                <input
                  type="text"
                  onChange={(e) => setReasonFilter(e.target.value)}
                  placeholder="Filter by Reason"
                />
              </th>
              <th className="whitespace-nowrap">Is Correct</th>
            </tr>
          </thead>
          <tbody>
            {filteredData &&
              filteredData.map(({ tag, evidence }, index) => (
                <tr key={index}>
                  <td>{tag}</td>
                  <td>{evidence.chunk}</td>
                  <td>
                    {evidence.isValid ? (
                      evidence.values.map((value, valueIndex) => (
                        <div key={valueIndex}>{value}</div>
                      ))
                    ) : (
                      <textarea
                        value={evidence.values?.[0]}
                        onChange={(e) => {
                          props.evidenceData
                            .find((data) => data.tag === tag)
                            .evidences.find(
                              (_evidence) =>
                                _evidence.chunk === evidence.originalChunk
                            ).values = [e.target.value];
                          props.setEvidenceData([...props.evidenceData]);
                        }}
                      />
                    )}
                  </td>
                  <td>{evidence.evidence}</td>
                  <td>{evidence.reason}</td>
                  <td>
                    <div className="flex justify-end h-full">
                      <input
                        type="checkbox"
                        checked={evidence.isValid}
                        onChange={(e) => {
                          props.evidenceData
                            .find((data) => data.tag === tag)
                            .evidences.find(
                              (_evidence) =>
                                _evidence.chunk === evidence.originalChunk
                            ).isValid = e.target.checked;
                          props.setEvidenceData([...props.evidenceData]);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
