import React, { useEffect, useMemo, useState } from "react";
import { sendRequest } from "../components/utilities/functions/api";
import { useUserProfile } from "../context/UserProfile";
import { Auth } from "aws-amplify";
import { ENDPOINTS } from "../api/endpoints";
import Paginator from "../components/Paginator/Paginator";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaMinimize } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";

type CatalogResponse = {
  catalog_names: string[];
  catalog: Record<
    string,
    {
      file_directory: string[];
      storage_type: string[];
      storage_name: string[];
      file_type: string[];
      file_size: string[];
      document_length: number[];
      word_count: number[];
      status: string[];
      chunks: Record<
        string,
        {
          document_length?: number;
          word_count?: number;
          status: string;
        } & Record<
          string,
          {
            value: string[];
            reason: string;
            evidence: string;
            successful: boolean;
          }
        >
      >;
    } & Record<string, string[]>
  >;
  filter_name: Record<
    string,
    {
      real: string;
      availableValues: string[];
      colour: string;
    }
  >;
  search_details: Record<
    string,
    {
      title: string;
      metadata_search_string: string;
    }
  >;
};

const stringToColor = (stringValue: string, opacity = "40") => {
  let hash = 0;
  for (let i = 0; i < stringValue.length; i++) {
    hash = stringValue.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    // Use parts of the hash for different color components
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color + opacity;
};

const Catalog = () => {
  const [catalogResponse, setCatalogResponse] = useState<CatalogResponse>();
  const [selectedCatalog, setSelectedCatalog] = useState("catalog");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const userProfile = useUserProfile();
  const [expandedFile, setExpandedFile] = useState("");
  const [actionsFile, setActionsFile] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!userProfile) return;
    if (catalogResponse) return;

    (async () => {
      const rawCatalogResponse = await sendRequest(
        {
          catalog_name: selectedCatalog,
          [(userProfile as { system: Record<string, string> }).system
            .API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser())
            .username,
        },
        ENDPOINTS["get_catalog"]
      );

      setCatalogResponse(await (rawCatalogResponse as any).json());
    })();
  }, [catalogResponse, selectedCatalog, userProfile]);

  const filteredCatalog = useMemo(() => {
    if (!catalogResponse?.catalog) return;
    const returnableObject = { ...catalogResponse.catalog };
    if (search) {
      for (const key in catalogResponse.catalog) {
        if (
          !key
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.toLowerCase().replace(/ /g, ""))
        ) {
          delete returnableObject[key];
        }
      }
    }
    return returnableObject;
  }, [catalogResponse?.catalog, search]);

  if (!filteredCatalog) return <></>;

  return (
    <div className="fixed inset-0 flex flex-col overflow-hidden">
      <div className="w-full bg-primary h-10 shrink-0"></div>
      <div className="bg-slate-200 w-full h-full flex justify-center overflow-hidden">
        <div className="flex flex-col w-full h-full p-4 items-center">
          <div className="flex items-center bg-white shadow-md w-full max-w-6xl  rounded-t-md overflow-hidden py-2">
            <div className="px-4">
              <IoSearch />
            </div>
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
              className="w-full pr-4 py-2 outline-none"
            />
          </div>

          <div className="w-full h-full max-w-6xl bg-white overflow-auto shadow-md">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="text-xs uppercase">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 w-96 top-0 sticky bg-gray-100 z-50"
                  >
                    File name
                  </th>
                  <th
                    scope="col"
                    className="text-center px-6 py-3 top-0 sticky bg-gray-100 z-50"
                  >
                    Tags
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-20 top-0 sticky bg-gray-100 z-50"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {Object.entries(filteredCatalog)
                  .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map(([key, tags]) => {
                    const isExpanded = key === expandedFile;
                    const isActionsOpened = key === actionsFile;
                    return (
                      <tr className="bg-white border-b">
                        <td className="px-6 py-4 text-sm w-96 break-all text-gray-700">
                          {key}
                        </td>
                        <td className="px-6 py-4 flex gap-1">
                          <div className="flex flex-col">
                            <div
                              className={`text-xs h-6 flex items-center justify-center transition-all cursor-pointer py-1 px-2 rounded-md text-white ${isExpanded ? "bg-sky-400" : "bg-blue-500"}`}
                              onClick={() => {
                                setExpandedFile(isExpanded ? "" : key);
                              }}
                            >
                              {isExpanded ? (
                                <FaMinimize />
                              ) : (
                                <FaExpandArrowsAlt />
                              )}
                            </div>
                          </div>
                          <div
                            className={`flex gap-1 transition-all flex-wrap ${isExpanded ? "max-h-none overflow-auto" : "max-h-6 overflow-hidden"}`}
                          >
                            {Object.entries(tags)
                              .filter(([key, value]) => {
                                return key !== "chunks" && Array.isArray(value);
                              })
                              .map(([key, value]) => (
                                <div
                                  className="text-xs py-1 px-2 rounded-md"
                                  style={{
                                    backgroundColor:
                                      stringToColor(
                                        (value as string[])[0],
                                        "40"
                                      ) || "#80808028",
                                  }}
                                >
                                  <span className="font-bold text-black">
                                    {key}:
                                  </span>{" "}
                                  {(value as string[])[0]}
                                </div>
                              ))}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-end relative">
                            <BsThreeDotsVertical
                              className="cursor-pointer"
                              onClick={() => {
                                setActionsFile(isActionsOpened ? "" : key);
                              }}
                            />
                            {isActionsOpened && (
                              <div className="absolute z-40 top-[calc(100%_+_8px)] w-40 p-2 bg-blue-50 rounded-md shadow-md flex flex-col">
                                <div className="cursor-pointer">View</div>
                                <div className="cursor-pointer">Delete</div>
                                <div className="cursor-pointer">Evidence</div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="bg-white shadow-md w-full max-w-6xl rounded-b-md">
            <Paginator
              onNewPage={(newPage) => setCurrentPage(newPage)}
              currentPage={currentPage}
              onNewPageSize={(newPageSize) => {
                setCurrentPage(1);
                setPageSize(newPageSize);
              }}
              pageSize={pageSize}
              numberOfItems={Object.keys(filteredCatalog).length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
